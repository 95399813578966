.books-ressources-header, .books-ressources-body,
.videos-ressources-body, .videos-ressources-header,
.photos-ressources-header, .photos-ressources-body{
    background: #EDEDED;
}

#photoPlayerModal .modal, #videoPlayerModal .modal{
    padding: 0px !important;
}
#photoPlayerModal .modal-dialog, #videoPlayerModal .modal-dialog{
    width:80vw !important;
    max-width: 80vw !important;
}
#photoPlayerModal .modal-content, #videoPlayerModal .modal-content{
    height:85vh;
    
}

.modal .close span{
    color:white;
}
.modal .close{
    position: absolute;
    right:20px;
    top: 20px;
}
.modal-content{
    background-color: #EDEDED;
    border-radius: 12px;
}
/*
.books-ressources-header,
.photos-ressources-header,
.videos-ressources-body{

}*/


.books-ressources-body .cards-wrapper-text span,
.photos-ressources-body .cards-wrapper-text span,
.videos-ressources-body .cards-wrapper-text span{
    font-size: 16px;
}


 .video-container, .photo-container{
    width: 100%;
    height: 100%;
}
.video-content{
    width: 100%;
    height:93%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.photo-content{
    width: 100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vid-progress-bar-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vid-bar--length{
    background: white;
    border-radius: 20px;
    width: 98%;
    height:12px;
    position: relative;
}
.vid-progress-bar{
    position: absolute;
    background: #EA0029;
    border-radius: 20px;
    width: 5%;
    height:12px;
    left: 0px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.progress-indicator{
    width: 20px;
    height:20px;
    border-radius: 50%;
    background-color: #EA0029;
    -webkit-box-shadow: 6px 7px 22px -8px rgba(0,0,0,1);
-moz-box-shadow: 6px 7px 22px -8px rgba(0,0,0,1);
box-shadow: 6px 7px 22px -8px rgba(0,0,0,1);
}

.cards-wrapper-elem button{
    border: none;
    border-radius: 12px;
}

#booksPlayerModal .pdf-icon-container{
    width: 250px;
    height:250px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 12px;
    -webkit-box-shadow: 4px 7px 11px -8px rgba(0,0,0,0.62);
    -moz-box-shadow: 4px 7px 11px -8px rgba(0,0,0,0.62);
    box-shadow: 4px 7px 11px -8px rgba(0,0,0,0.62);
}

#booksPlayerModal .pdf-icon-content img{
    width: 50px;
}

#booksPlayerModal .modal-body-content{
    display: inline-flex;
    flex-direction:row-reverse;
    align-items: center;
    width: 80%;
    gap:50px;
    padding:60px 0px;
    
}
#booksPlayerModal .modal-body{
    display: flex;
    align-items: center;
    justify-content: center;
}
#booksPlayerModal .bks-options-btns-container{
    display: flex;
    flex-direction: column;
    gap:20px;
    width: 50%;
}

@media screen and (max-width: 600px) {
    #booksPlayerModal .modal-body-content{
        flex-direction: column;
        padding:10px 0px;
    }
}
@media screen and (min-width: 900px) {
    #booksPlayerModal .modal-content{
        width: 70vw !important;
        height:70vh !important;
    }
    #booksPlayerModal .pdf-icon-container{
        height: 80%;
    }
    #booksPlayerModal .modal-body-content{
        padding:0px;
    }
    #booksPlayerModal .modal-body-content{
        width:100%;
        height: 100%;
        justify-content: space-between;
        padding: 30px 50px;
    }
}
