.certif-carousel-container .dashboard-column-content, 
.certif-carousel-container .dashboard-column-elem,
.certif-carousel-container .dashboard-column-elem-content,
.certif-carousel-container .user-certifications-container{
    height:100% !important;
}

.certif-carousel-container .dashboard-column-elem-content{
    background: white;
    border-radius: 12px;
}
.certif-carousel-container .carousel-inner{
    width: 80%;
}
#certifCarousel a{
    position: relative;
}
#certifCarousel{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bsCertifSlider{
    width: 90%;
}
.certif-carousel-container .user-certifications-content{
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.download-certif-btn-container button{
    border: none;
    padding:10px 15px;
    border-radius: 10px;
    background: #707070;
    color:white;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap:10px;

}
@media screen and (max-width: 900px) {
    .user-certifications-content{
        gap:30px;
        padding:20px;
    }
}

.nav-icon-container svg{
    height:20px !important;
    width: 20px !important;
}