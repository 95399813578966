.registered-user-view-header .hs-navbar--left-side{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap:15px;
    }

.registered-user-view-header .hs-navbar--right-side {
        display: flex;
        align-items: center;
        gap:10px;
        }

.registered-user-view-header{    
    background: #EDEDED;
}

.course-video-content{
    display: flex;
    flex-direction: column;
    gap:20px;
}

@media screen and (min-width: 600px){
    .registered-user-view-header .header-search-box-content svg{
        display: block;
    }
    .registered-user-view-header .header-search-box-content svg{
        width: 15px;
    }
    .registered-user-view-header .hs-navbar--right-side{
        gap:20px;
    }
}

@media screen and (min-width: 800px){
    .registered-user-view-header .header-search-box-content svg{
        width: 18px;
    }
}

@media screen and (min-width: 900px){
    .registered-user-view-header .hs-navbar--right-side{
        gap:40px;
    }
    .registered-user-view-header .header-search-box-content{
        padding:8px;
    }
}
.registered-user-view-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    gap:15px;
}
.registered-user-view-content{
    width: 100%;
    padding: 20px;
}
.user-main-score{
    width: 100%;
    height:100px;
    background: #5A5A5A;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}
.user-main-score-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100%;
}
.user-main-score-content span{
    font-weight: 700;
    color:white;
}
.registered-user-view-container .SideNavMenu-btn--Mob{
    display: flex;
    flex-direction: column;
    gap:15px;
}










.registered-user-view-column{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.registered-user-view-column-header-elem-img-container img{
    border-radius: 50%;
    width: 40px;
    height:40px;
    object-fit: cover;
}
.registered-user-view-column-header-elem svg{
    width: 15px;
}
.registered-user-view-column-header-elem{
    display: flex;
    align-items: center;
    gap:15px;
}
.registered-user-view-column-header-elem-course-rating-content svg{
    width: 30px;
    height:30px;
}
.registered-user-view-column-header-content{
    display: flex;
    flex-direction: column;
    gap:10px;
    background: white;
    border-radius: 10px;
    padding:20px;
}
.dshbrd header-vert-spr{
    display: none;
}
.course-video-header{
    background: #EA0029;
    padding:10px;
    border-radius: 6px;
}
.course-video-header span{
    color:white;
    font-weight: 700;
}

.course-video-body-content .vid-play-button img{
width: 80px !important;
height:80px !important;
}
.course-video-body-content .vid-progress-bar, .course-video-body-content .vid-bar--length{height:8px !important;}
.course-video-body-content .progress-indicator{
    height:16px;
    width: 16px;
}
.course-video-body-content .video-content{
    width: 100%;
    height:45vw;
}
.course-video-body-content .vid-progress-bar{
    width:10%;
}
.course-video-body-content{
    padding: 15px;
    background: white;
    border-radius: 10px;
}
.vid-bar--length, .progress-indicator{
    -webkit-box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2);
-moz-box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2);
box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2);
}

.summary-title .course-chapter-name{
    background: #5A5A5A;
}
.course-chapter-name{
    background: #ff637f;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
}

.course-summary .course-summary-content, .course-summary-elem{
width: 100%;
}
.course-summary-elem, .course-chapter-content{
    display: flex;
    flex-direction: column;
    gap:15px;
}

.course-chapter-name span{
    font-weight: 700;
    color:white;
}
.course-chapter-elem{
    padding:10px;
    background: white;
    border-radius: 12px;
}
.course-chapter-elem span{
    font-weight: 700;
    color:rgb(97, 97, 97);
}

.course-chapter-content .course-chapter-elem:last-child{
    background: #0050219c;
}
.course-chapter-content .course-chapter-elem:last-child span{
    color:rgba(219, 219, 219, 0.582);
}
/*before last*/
.completed-chap .course-summary-elem:nth-last-child(2) span{
    color:black !important;
}
.course-summary-elem:nth-last-child(2) .course-chapter-elem{
    background: #DEE0E6 !important;
}
.course-summary-elem:nth-last-child(2) .course-chapter-elem span{
    color:rgb(97, 97, 97);}
/*last*/
.course-summary-elem:last-child .course-chapter-elem{
    background: #5a5a5ad2 !important;
}
.course-summary-elem:last-child .course-chapter-elem span{
    color:#ffffffde;}

/* .completed-chap .course-chapter-elem span{
    color: black !important;
} */
.completed-chap .course-chapter-name{
    background:#EA0029;}
.completed-chap .course-chapter-elem:last-child{
        background:#00732F;}

.course-chapter-elem:nth-child(1) .course-chapter-elem:last-child span{
            color:black !important;}
.course-summary-elem:not(:nth-last-child(2)):not(:nth-child(1)) .course-chapter-elem:last-child span{
            color:white;}


.course-chapter-name, .course-chapter-elem{
    --webkit-box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2);
    -moz-box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2);
    box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2);
}

.summary-title .course-chapter-content{
    margin-top: 20px;
}
.summary-title .course-chapter-elem{
    background: white !important;
}
.summary-title .course-chapter-elem span{
    color:black !important;
}


.course-summary-content{
    background: white;
    padding:30px;
    border-radius: 12px;
    --webkit-box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2);
    -moz-box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2);
    box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2);
}

.course-video-header, .course-video-body, .registered-user-view-column-header, .registered-user-view .video-container{
    --webkit-box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2);
    -moz-box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2);
    box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2);
}


.header-vert-spr{
    height:20px;
    width: 2px;
    background: black;
    opacity: 0.2;
    display: none;
}
.registered-user-view-column-header-content span{
    font-weight: 700;
}
.registered-user-view .video-container{
background: #EDEDED;
border-radius: 10px;
padding:15px;
}
.registered-user-view .vid-progress-bar-container{
    padding-bottom: 15px;
}
.course-video-body-content{
    display: flex;
    flex-direction: column;
    gap:20px;
}

.registered-user-view .course-summary-content{
    gap:40px !important;
}

.registered-user-view-content .course-catalogue-content{
    padding:0px;
}
.ctlg-courses-cpy .course-catalogue-container, 
.ctlg-courses-cpy .course-catalogue-content,
.ctlg-courses-cpy .course-catalogue-elem,
.ctlg-courses-cpy .course-info-desc,
.ctlg-courses-cpy .course-summary-content{
    width: 100%;
}
.ctlg-courses-cpy .course-summary-content{
    padding:0px;
}

.ctlg-courses-cpy .course-summary-content{
    background: none;
    box-shadow: none;
}
.gamification-sys-container,
.knowledge-test-sys-container{
    width:100%;
}
.gamification-sys-header,
.knowledge-test-sys-header{
    background: #EA0029;
    padding: 10px 20px;
    border-radius: 6px;
}
.gamification-sys-header span,
.knowledge-test-sys-header span{
    color:white;
    font-weight: 700;
}
.gamification-sys-body-elem,
.knowledge-test-sys-body-elem{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.gamification-sys-body-elem:nth-child(1){
    padding:40px 20px !important;
}

.gamification-sys-body-content .gamification-sys-body-elem{
    background: white;
    padding:10px 20px;
    border-radius: 12px;
}
.gamification-sys-test-container{
    width: 100%;
}
.gamification-sys-test-content{
    background: white;
    width:100%;
    padding:20px;
    border-radius: 12px;
    display:flex;
    flex-direction: column;
    gap:20px;
    align-items: center;
}
.gamification-sys-test-desc{
    display:flex;
    flex-direction: column;
    gap:30px;
    align-items: center;
}
.gamification-sys-test-title span{
    color:#00732F;
    font-weight: 700;
}
.gamification-sys-test-desc span{
    font-weight: 700;
}
.test-detls-desc span{
    font-size: 12px;
}
.gamification-sys-test-img-cont{
    width: 100%;
    height:50%;
}
.gamification-sys-test-img-cont img{
    width: 100%;
    height:100%;
}
.gamification-sys-content, .gamification-sys-body-content{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.gamification-sys-body-elem span{
    font-weight: 700;
}
.knowledge-test-sys-question-container{
    width: 100%;
}
.question-multiple-choice-row, .question-multiple-choice-res-row{
    display: flex;
    align-items: center;
    gap:10px;
}
.question-multiple-choice, .question-multiple-choice-res{
    background: white;
    border-radius: 10px;
    padding:20px;
}
.knowledge-test-sys-question-title-content{
    background: white;
    border-radius: 10px;
    padding:5px 20px;
    width: auto !important;
    display: inline-block;
}

.actual-question{
    padding-top: 10px;
    padding-bottom: 10px;
}
.question-multiple-choice form{
    display: flex;
    flex-direction: column;
    gap:6px;
}
.knowledge-test-sys-question-title-content, .knowledge-test-sys-question-body, .knowledge-test-sys-header{
    -webkit-box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2);
-moz-box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2);
box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2);
}


.question-multiple-choice-row label, .question-multiple-choice-res-row label{
    margin: 0px !important;
}
.knowledge-test-sys-question-title span, .actual-question span{
    font-weight:700;
}
.knowledge-test-sys-question-content, .knowledge-test-sys-content{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.knowledge-test-sys-question-content button{
    width: auto !important;
}
.knowledge-test-sys-body-content{
    display:flex;
    flex-direction: column;
    gap:35px;
}
.-test--res{
    -webkit-box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2);
    -moz-box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2);
    box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2);
}
.-test--res-content{
    display: flex;
    flex-direction: column;
    gap:30px;
    align-items: center;
    font-weight: 700;
    background: white;
    padding:40px 20px;
    border-radius: 10px;
    width: 100%;
}
.-test--res-title{
    font-size:24px !important;
    margin-bottom: 20px;
}
.test-timing{
    flex-direction: column;
    display:flex;
    align-items: center;
    gap:10px;
}

.test-timing-dateandtimecont{
    display:flex;
    align-items: center;
    gap:10px;
}

/*perc-ring Css*/
.test-res-stats-ring svg {
    transform: rotate(-90deg);
    width: 100%;
    height: 100%;
    fill: none;
  }
  .test-res-stats-ring svg circle, .test-res-stats-ring svg circle {
    strokeWidth: 3;
    stroke: rgb(216, 216, 216);
  }
  .test-res-stats-ring svg .bar, .test-res-stats-ring svg .bar  {
    strokeWidth: 7;
    stroke-linecap: round;
  }
  .-gray-stats--bar svg .bar{
    stroke: #5A5A5A !important;
  }
  .-green-stats--bar svg .bar{
    stroke: #00732F !important;
  }
  .-red-stats--bar svg .bar{
    stroke: #EA0029 !important;
  }
  .-black-stats--bar svg .bar{
    stroke: black !important;
  }

  .test-res-stats{
      display: flex;
      align-items: center;
      gap:40px;
  }

  .test-res-stats-Percring-content{
    width: 100px;
    height:100px;
}
.test-res-stats-Percring{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.test-res-stats-Percring .perc-in-number span{
font-size: 25px;
}
.test-res-stats{
    margin-top: 20px;
}
.question-multiple-choice-res-row{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.knowledge-test-sys-question-body{
    background: white;
    padding:20px;
    border-radius: 10px;
}
.question-multiple-choice-res{
    padding:0px;
}
.question-multiple-choice-res-elem{
    display: flex;
    align-items: center;
    gap:10px;
}
.wrong-answer .question-res span{
color:#EA0029;
font-weight: 700;
}
.correct-answer .question-res span{
    color:#00732F;
    font-weight: 700;
    }
.MCwrong-answer span{
color:#00732F;
font-weight: 700;
}
.MCcorrect-answer span{
    color:#EA0029;
    font-weight: 700;
}
.actual-question span{
    font-size: 18px;
}
.knowledge-test-sys-question-btn-cont button{
    font-size: 16px !important;
}
.knowledge-test-sys-question-btn-cont button img{
    width: 20px;
}
.knowledge-certif-img-cont, .knowledge-certif{
    width:80%;
}
.knowledge-certif-img-cont img, .knowledge-certif img{
    width:100%;
}
.knowledge-certif-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:70px;
}
.knowledge-certif-buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px;
}
.knowledge-certif-buttons button,
.knowledge-certif-buttons .btn-red{
    padding: 0px !important;
    font-size: 13px !important;
    height:40px;
    width: 150px;
    border-radius: 12px;
    -webkit-box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2) !important;
    -moz-box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2) !important;
    box-shadow: -1px 1px 7px -2px rgba(0,0,0,0.2) !important;
}
.knowledge-certif-buttons button img{
    width: 25px;
}
.btn-gray{
    background: #5A5A5A;
    color:white;
}

.knowledge-certif-buttons{
    margin-bottom: 90px;
}
.knowledge-certif-title{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top:20px;
}
.knowledge-certif-title span{
    font-weight: 700;
    font-size:30px;
}


@media screen and (min-width: 1600px){
    .knowledge-certif-img-cont, .knowledge-certif{
        width:690px;
    }
}


@media screen and (min-width: 500px){
    .test-timing{
        flex-direction: row;
    }

}

@media screen and (min-width: 650px){
    .knowledge-certif-buttons{
        flex-direction: row;
    }
}

@media screen and (min-width: 700px){
    .registered-user-view-column-header-content{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .header-vert-spr{
        display: block;
    }
    
}

@media screen and (min-width: 900px){
    .registered-user-view .registered-user-view-column{
        width: 100%;
    }
    .course-summary{
        width:70%;
    }
}
@media screen and (min-width: 1000px){
    .registered-user-view .registered-user-view-column-elem-row{
       display: flex;
       flex-direction: row-reverse;
       gap:20px;
    }
    .course-video-container{
        width: 100%;
        
    }
    .course-video-body-content .video-content{
        width: 100%;
        height:22vw;
    }
}
@media screen and (min-width: 1100px){
    .course-summary{
        width:60%;
    }
}

/*
.registered-user-view-main-cont .sidebar-container{
    height:100%;
    position: relative;
}
.registered-user-view-main-cont .sidebar-content{
    position: fixed;
}*/
.registered-user-view-main-cont .sidebar-container{
    height:100%;
    position: relative;
}

.--SideNavMenu-btn--Mob button{
    border: none;
    background: #5A5A5A;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    height: 70px;
    display: flex;
    align-items: center;
}

.--SideNavMenu-btn--Mob svg:nth-child(1) {
    height: 45px;
}
.--SideNavMenu-btn--Mob svg:nth-child(2) {
    height: 30px;
}

.--SideNavMenu-btn--Mob button, .SideNavMenu-btn--Mob-content{
    height: 110px;
    display: flex;
    align-items: center;
}

.sidenavbar-modal{
    padding:0px !important;
    margin: 0px !important;
}
@media screen and (min-width: 1300px){
    .sidenavbar-modal .modal-dialog{
        margin: 0px !important;
        width: 20% !important;
       max-width: 60% !important;
    }
}
@media screen and (min-width: 500px){
    .sidenavbar-modal .modal-dialog{
        width:350px;
    }
}
.sidenavbar-modal .modal-dialog{
    margin: 0px !important;
    max-width: 60% !important;
}
.sidenavbar-modal .modal-content{
    margin-top: 150px;
    background: none;
}
.sidenavbar-modal .sidenavbar{
    width: 100% !important;
}

.registered-user-view-main-cont .course-miniature{
    width: 100%;
}




@media screen and (min-width: 1300px){
    .registered-user-view-main-cont .sidebar-content{
    height:auto !important;
}
    .registered-user-view-content{
        padding:0px;
    }
    .registered-user-view-main-cont{
        padding:60px 0px 60px 60px;
    }
    /*
    .registered-user-view-main-cont .sidebar-container{
        height:auto;
    }*/
    .course-video-body-content{
        padding:30px;
    }
    .registered-user-view-column-header-elem-img-container img{
        width: 70px;
        height:70px;
    }
    .registered-user-view-header .sidebar-container .SideNavMenu-btn--Mob{
        display: block !important;
        position: relative;
    }
    .SideNavMenu-btn--Mob-content button, .--SideNavMenu-btn--Mob .user-main-score{
        height:110px;
    }
    .--SideNavMenu-btn--Mob{
        display: flex;
        flex-direction: column;
        gap:40px;
    }

    .--SideNavMenu-btn--Mob .SideNavMenu-btn--Mob-content, .--SideNavMenu-btn--Mob .user-main-score{
        width: 90px;
    }

    .registered-user-view-main-cont .sidenavbar{
        width: 100px;
    }
    .SideNavMenu-btn--Mob-content .sidenavmenu--mob-ver{
        position: relative;
    }
    .registered-user-view-column-header-content{
        padding:20px 40px;
    }
    .registered-user-view-column{
        gap:40px !important;
    }
    .registered-user-view-column-elem-row{
        gap:20px !important;
    }
}


@media screen and (min-width: 1330px){
    .registered-user-view .dashboard-body .registered-user-view-column:nth-child(1){
        width: 100% !important;
    }  
}
@media screen and (min-width: 1440px){
    .registered-user-view-main-cont .sidenavbar{
        width:100px ;
    }
}
@media screen and (min-width: 1600px){
    .side-menu-nav-container{
        padding-bottom:20px;
    }
    .registered-user-view-main-cont{
        padding-left:100px;
    }
}

@media screen and (min-width: 1750px){
    .registered-user-view-main-cont{
        padding-left:140px;
    }
}
@media screen and (min-width: 1920px){

    .registered-user-view-main-cont{
        padding-left:160px;
    }

}

.ActiveSideBAr .sidenavbar{
        width: 18% !important;
}