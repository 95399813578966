#photoPlayerModal .modal-dialog{
    width: 100vw !important;
}
#photoPlayerModal .modal-dialog .carousel{
    width: 100%;
    height:100%;
}
#photoPlayerModal .modal-content{
    width: 100% !important;
    height:90%;
    background:none !important;
    border: none !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}
#photoPlayerModal .modal-dialog{
    height:90%;
}
#photoPlayerModal .modal-dialog a{
    width:5% !important;
}
#photoPlayerModal .modal-body{
    margin:0px 40px;
    background-color: rgb(237, 237, 237);
    border-radius: 12px;
    height: 100%;
    width: 90%;
    padding:0px;
}
#photoPlayerModal .carousel-inner, #photoPlayerModal .carousel-item{
    width:100%;
    height:100%;
    border-radius: 10px;
}
#photoPlayerModal .photo-icon{
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;  
}
#photoPlayerModal .carousel-item img{
border-radius: 10px;
}