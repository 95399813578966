.dashboard-user-progress-stats{
    background: #00732F;
    width: 70px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 21px;
}
.dashboard-user-progress-stats span{
    color:white;
    font-weight: 700;
}
.dashboard-user-progress-text span{
    font-weight: 700;
}
.dashboard-user-progress-info-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dashboard-user-progress-info-content{
    display: flex;
    flex-direction: column;
    gap:30px;
    padding: 10px 8px;
}
.dashboard-user-progress-info{
    background: white;
    padding: 15px 20px;
    border-radius: 12px;
}
.user-progress-cpr-others-content{
    background: white;
    border-radius: 12px;
    padding:20px 15px;
}
.user-progress-cpr-others-stats{
    width: 100%;
    height:250px;
    display: flex;
   justify-content: space-between;
}
.ver-progress-bar{
    width: 8px;
    border: 12px;
    border-radius: 21px;
}

.user-lvl-stats .ver-progress-bar{
    background:#5A5A5A;
    height:20%;
}
.user-lvl-stats .stats-in-num{
    color:#5A5A5A;
}
.user-badges-stats .ver-progress-bar{
    background:#EA0029;
    height:40%;
}
.user-badges-stats .stats-in-num{
    color:#EA0029;
}
.user-score-stats .ver-progress-bar{
    background:#00732F;
    height:60%;
}
.user-score-stats .stats-in-num{
    color:#00732F;
}
.user-certificats-stats .ver-progress-bar{
    background:black;
    height:100%;
}
.user-progress-cpr-others-body span{
    font-weight: 500;
}

.user-progress-cpr-others-content{
    display: flex;
    flex-direction: column;
    gap:10px;
}
.user-progress-cpr-others-title span{
    font-weight: 700; 
    font-size: 18px;
}

.user-progress-cpr-others-stats-column{
    height:100%;
}
.user-progress-cpr-others-stats-column-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap:8px;
    height:100%;
}
.unlocked-badges-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.unlocked-badges-container{
    background: #EA0029;
    padding:25px 0px;
    border-radius: 12px;
}

.unlocked-badges-text-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:white;
    font-weight: 700;
    padding-top:25px;
}
.unlocked-bhadges-in-perc{
    font-size: 20px;
}


.vert-line{
    height:30px;
    width:2px;
    background: black;
}
.overall-progress-stats-content{
    background:white;
    border-radius: 12px;
}

/*Overall stats */
.overall-progress-stats-content, .overall-progress-stats-content{
    display: flex;
    flex-wrap: wrap;
    gap:25px;
    align-items: center;
    justify-content: center;
}
.overall-progress-stats-elem, .overall-progress-stats-elem{
    display: flex;
    flex-direction: column;
    gap:15px;
    width: 230px;
}
.courses-stats, .overall-progress-stats, .overall-progress-stats{
    display: flex;
    flex-direction: column;
    gap:15px;
    background: white;
    border-radius: 12px;
    padding:20px;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
}
.overall-progress-stats, .overall-progress-stats{
padding:30px 20px;
}

/*perc-ring Css*/
.overall-progress-stats-elem svg, .overall-progress-stats-elem svg {
    transform: rotate(-90deg);
    width: 100%;
    height: 100%;
    fill: none;
  }
  .overall-progress-stats-elem svg circle, .overall-progress-stats-elem svg circle {
    strokeWidth: 3;
    stroke: rgb(216, 216, 216);
  }
  .overall-progress-stats-elem svg .bar, .overall-progress-stats-elem svg .bar  {
    strokeWidth: 7;
    stroke-linecap: round;
  }

  .overall-progress-stat-perc-ring-content, .overall-progress-stat-perc-ring-content{
    width: 188px;
    height:188px;
}
.overall-progress-stat-perc-ring, .overall-progress-stat-perc-ring{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.overall-progress-stats-elem, .overall-progress-stats-elem{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.overall-progress-stats-header span, .courses-stats-header span, .overall-progress-stats-header span{
    font-weight: 700;
    font-size:20px;
}

@media screen and (min-width: 1000px){
    .overall-progress-stat-perc-ring-content, .overall-progress-stat-perc-ring-content{
        width:120px;
        height:120px;
    }
    .overall-progress-stats-elem, .overall-progress-stats-elem{
        width:180px !important;
    }
    .overall-progress-stats-content, .overall-progress-stats-content{
        flex-wrap: nowrap;
    }
}

.graph-control-container select{
    border: none;
    padding-left:25px;
}
.graph-control-content{
    display: flex;
    align-items: center;
    gap:10px;
}
.stats-graph-body-content{
    flex-direction: row-reverse;
    display: flex;
    align-items: center;
}
.stats-graph-content{
    background: white;
    border-radius: 12px;
    height:100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.stats-graph-header{
    display: flex;
    flex-direction: column;
    gap:7px;
}
.stats-graph-header span{
    font-weight: 700;
    font-size:20px;
}
.graph-control-content span, .graph-control-content select, .graph-control-content select option{
    font-weight: 500;
    font-size:16px;
}

.user-progress-dashboard .art-container{
    display: none !important;
}
.actual-grah-container{
    width: 100%;
}
.actual-grah-container img{
    width: 100%;
}
.user-progress-dashboard .art-container img{
width: 100%;
}

@media screen and (min-width: 1000px){
    .user-progress-dashboard .art-container{
        display: flex !important;
        width: 60%;
    }
    .user-progress-dashboard .actual-grah-container{
        width: 80%;
    }
}

@media screen and (min-width: 1300px){
    .graph-control-container span,  .graph-control-container select{
        font-size: 14px !important;
    }
    .overall-progress-stats, .overall-progress-stats{
        padding:20px 10px;
    }
    .overall-progress-stats-elem, .overall-progress-stats-elem{
        width: 120px !important;
    }
    .overall-progress-stats-content, .overall-progress-stats-content{
        gap:15px;
    }
    .overall-progress-stat-perc-ring-content, .overall-progress-stat-perc-ring-content{
        width: 80px;
        height:80px;
    }
    .dashboard-stats-content .courses-stats, .dashboard-stats-content .overall-progress-stats, .overall-progress-stats{
        width: 50%;
    }
    .overall-progress-stats, .overall-progress-stats{
        gap:30px;
    }
    .overall-progress-stats-content{
        width: 71%;
    }
    .unlocked-badges-container{
        width: 29%;
    }
    .stats-graph-container{
        height:100%;
    }
    .SideNavMenu-btn--Mob{
        display: none !important;
    }
}
.SideNavMenu-btn--Mob-content button{
    border:none;
    background: #5A5A5A;
border-top-left-radius: 12px;
border-bottom-left-radius: 12px;
height:70px;
display: flex;
align-items: center;
}
.SideNavMenu-btn--Mob-content button svg:nth-child(1){
    height:45px;
}
.SideNavMenu-btn--Mob-content button svg:nth-child(2){
    height:30px;
}
.overall-progress-stats-content{
    padding:20px 0px;
}
.overall-progress-stats-header, .overall-progress-stats-header{
    padding-right:10px;
}

@media screen and (min-width: 1440px){
    .overall-progress-stat-perc-ring-content, .overall-progress-stat-perc-ring-content{
        width: 90px;
        height:90px;
    }
    .user-progress-dashboard .dashboard-column:nth-child(1) .dashboard-column-content{
        display: flex;
        flex-direction:column;
    }
    .-pcr-co{
       height:330px;
    }
    .-p-progr-info{
        height:100%;
    }
    .dashboard-user-progress-info{
        height:100%;
    }
    .dashboard-column-content{
        gap:10px;
    }
}

@media screen and (min-width: 1600px){
    .overall-progress-stat-perc-ring-content, .overall-progress-stat-perc-ring-content{
        width:100px;
        height:100px;
    }
}


.sidenavmenu--mob-ver{
    position: absolute;
    right: 0;
    z-index: 100000;
}
.sidenavmenu--mob-ver .side-menu-content{
    width: 250px;
}


@media screen and (max-width: 600px){
    .admin-view-dashboard .stats-graph-body .art-container{
        display: none !important;
    }
}

@media screen and (min-width: 900px) and (max-width: 1200px){
    .admin-view-dashboard .stats-graph-body .art-container{
        display: none !important;
    }
}
@media screen and (min-width: 900px) and (max-width: 1300px){
    .admin-view-dashboard .stats-graph-body .art-container{
        display: none !important;
    }
}

@media screen and (min-width: 1300px){
    .admin-view-dashboard .stats-graph-body .art-container{
        width: 50%;
    }
    .admin-view-dashboard .stats-graph-body .art-container img{
        width: 100%;
    }
    .admin-view-dashboard .stats-graph-body .graph-placeholder{
        width: 90%;
    }
}