
*{
    margin: 0;
    padding: 0;
}
input:focus, select:focus{
border: none !important;
outline: none !important;
box-shadow: none !important;
}

button:focus{
    outline: none !important;
    box-shadow: none !important;
}


body{
    unicode-bidi: bidi-override !important;
    direction: unset !important;
    text-align:right;
    font-family: 'Tajawal', sans-serif;
    
}


.hs-logo--container{
width: 75px;

}
.hs-navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:10px 20px;    
}

.hs-slider-container .carousel-indicators li{
    width: 1.5vw !important;
    height: 1.5vw !important;
    border-radius: 50%;
    opacity:  .5;
    margin-left: 5px !important;
    margin-right: 5px !important;
    background: lightgrey;
    background-clip:padding-box !important;
}

.carousel-indicators .active{
    background: #00732F !important;
    opacity:1 !important;
    background-clip:padding-box !important;

}

.hs-slider-banner-container{
    height:250px !important;
}


.hs-slider-banner-content .art-container{
    position: relative;
}
.hs-slider-banner-content  .des-text{
    position: absolute;
    top: 50px;
    right: 30px;
    width: 40%;
    z-index: 10;

}

.hs-slider-banner-content  .des-text span{
    font-weight: 700;
    font-size: 24px;
}


/*Body Section*/
.subsection--gray{
    background-color: #EDEDED;
    padding:100px 0px;
}
.subsection--white{
    background-color: white;
    padding:50px 0px;
}

.bs-subesction-content{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0px 30px;
    gap: 50px;
}
.bs-subsection-elem{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.bs-subsection-elem-title span{
    font-weight: 700;
    font-size: 24px;
}
.bs-subsection-elem-desc{
    text-align: center;
}
.bs-subsection-elem{
    width: 100%;
}
.bs-subsection-slider{
    width: 100%;
}

.bsSliderInter .carousel a, .bsSliderPart .carousel{
    position: relative !important;
}
.bs-subsection-slider .carousel{
    display: flex;
}
.bsSliderInter, .bsSliderPart{
    width: 100%;
}

.bsSliderInter .carousel, .container, .bsSliderPart .carousel{
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
    justify-content: center;
    width: 100%;
    gap:10px;
    padding: 0 !important;  
}
.bsSliderInter a{
    text-decoration: none;
    color: black;
}
.bsSliderInter a:hover{
    text-decoration: none;
}
.bsSliderInter .carousel-elem-box-img{
    width:30vw;
    height:30vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bsSliderPart .carousel{
    padding:20px 0px !important;
    -webkit-box-shadow: -4px 52px 99px -82px rgba(0,0,0,0.75) !important;
    -moz-box-shadow: -4px 52px 99px -82px rgba(0,0,0,0.75) !important;
    box-shadow: -4px 52px 99px -82px rgba(0,0,0,0.75) !important;
}
.bsSliderPart .carousel-elem-box-img{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0px 20px;
}

.bsSliderPart .carousel-elem-box-img img{
    width: 60%;
    height:auto;
    object-fit: cover;
}

.bsSliderInter .carousel-elem-box-img img{
    width: 100%;
    height: 100%;
}

.bsSliderInter .carousel-inner{
    padding:20px 0px ;
}
.bsSliderInter .carousel-elem-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background:white;
    width: 90%;
    height:50vw;
    gap:8px;
    padding-top: 5px;
    border-radius: 12px;
    -webkit-box-shadow: 0px 6px 16px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 6px 16px -6px rgba(0,0,0,0.75);
    box-shadow: 0px 6px 16px -6px rgba(0,0,0,0.75);
}


.bsSliderInter .carousel-elem-box-text{
    width: 100%;
    display: inline-flex;
    height: 40px;
    align-items: center;
    justify-content: center;
}
.bsSliderInter .carousel-elem-box-text span{
    margin: 0px auto;
    font-size: 14px;
    text-align:center !important;
    font-weight: 700;
}



/*memebers*/
.bs-subsection-elem-cards-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap:60px;
    margin-top: 30px;
}
.bs-subsection-elem-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:30px;
}
.bs-cards-content{
    width: 50vw;
    height: 50vw;
    border-radius: 50%;
    background: white;
    -webkit-box-shadow: 0px 6px 16px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 6px 16px -6px rgba(0,0,0,0.75);
    box-shadow: 0px 6px 16px -6px rgba(0,0,0,0.75);
    display: flex;
    align-items: center;
    justify-content: center;
}
.bs-cards-content img{
    width: 60%;
    height: 60%;
    object-fit: contain;
}
.bs-card-text span{
    font-weight: bold;
    font-size: 26px;
}
.bs-wrapper-sub-hirStruct-elem-title span{
    color:#EA0029;
    font-size: 24px;
    font-weight: 700;
}
.bs-wrapper-sub-hirStruct-elem-desc span{
    font-size: 16px;
    font-weight: 600;
}


.bs-wrapper-sub-hirStruct-elem, .diff-sub--elem{
    background:#FBFBFB;
    padding:25px;
    border-radius: 12px;

}
.bs-wrapper-sub-hirStruct, .bs-wrapper-sub--elem-content, .bs-subsection-elem-wrapper-content, 
.bs-wrapper-sub--elem-upper-section, .bs-wrapper-sub-hirStruct-elem{
    display: flex;
    flex-direction: column;
    gap:10px;
}

.bs-wrapper-sub-hirStruct-elem-desc{
    display: flex;
    flex-direction: column;
    gap:10px;
}



.bs-subsection-elem--value-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bs-subsection-elem--value-cards{
    width: 100%;
}
.bs-subsection-elem--value-card{
    width: 100%;
    padding:20px 40px;
    background: #FBFBFB;
    border-radius: 12px;
    -webkit-box-shadow: -4px 8px 24px -22px rgba(0,0,0,0.55);
    -moz-box-shadow: -4px 8px 24px -22px rgba(0,0,0,0.55);
    box-shadow: -4px 8px 24px -22px rgba(0,0,0,0.55);
}
.art-icon-cont{
    width:100px;
    height:100px;
    background: white;
    padding:25px;
    border-radius:50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bs-subsection-elem--value-card .art-icon-cont{
    padding: 0px !important;
}
.art-icon-cont img{
    width: 100% !important;
    height:100% !important;
}
.bs-subsection-elem--value-cards{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:30px;
}



.footer-container{
    background:black;
}

.footer-elem-title span{
    color:#EA0029 !important;
    font-weight: bold !important;
}

.footer-elem-body-unit .footer-elem-body-unit-content{
    width: 90%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.footer-elem-body-unit a{
    width: 35px;
}
.footer-elem-body-unit a svg{
    width: 100%;
}

.footer-elem-body-unit span{
    font-weight: 500;
    color: white;
}
.footer-elem{
    padding:30px;
    display: flex;
    flex-direction: column;
    gap:10px;
}
.footer-elem-body{ display: flex;
    flex-direction: column;
    gap:15px;
}

.footer-elem-body-col{
    display: flex;
    flex-direction: column;
    gap:15px;
}
.footer-elem-body-col a{
    text-decoration: none;
    font-weight: 500;
    color: white;
}
.footer-elem-body-cols-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.copyright-container{
    margin-top: 50px;
}
.copyright-content{
    display: flex;
    align-items: center;
    justify-content: center;
}
.news-letter-subs-container{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap:15px;
}
.news-letter-subs-container input, .news-letter-subs-container button{
    font-weight: 700;
    font-size: 14px;
    border-radius: 10px;
}

.news-letter-subs-container input{
    border: none !important;
outline: none !important;
box-shadow: none !important;

}
.news-letter-subs-container{
    margin-top: 10px;
}
.-log--container{
    width: 180px;
}
.-log--container svg{
    width: 100%;
}

.flex-container {
    padding: 0;
    margin: 0;
    list-style: none;
    
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }


.value-card-title span{
    font-weight: bold;
    font-size: 20px;
}


@media screen and (min-width: 500px) {
      .bsSliderInter .carousel-elem-box{
          height:32vw !important;
      }
      .carousel-control-next, .carousel-control-prev{
          width:10%;
      }
      .bs-subsection-elem-cards-container{
          width: 100%;
      }
      .bs-subsection-elem-cards-content{
            width: 100%;
            display: flex;
            flex-direction: row !important;
            flex-wrap: wrap !important;
      }
      .bs-cards-content{
          width: 20vw;
          height:20vw;
      }
      .bs-wrapper-sub-hirStruct:nth-child(2){
          display: flex;
          flex-direction: row;
      }
      .bs-wrapper-sub-hirStruct:nth-child(2) > *{
          width: 100%;
      }
      .value-card-title span{
        font-weight: bold;
        font-size: 24px;
    }
    .bsSliderInter .carousel-elem-box{
        padding: top 0px;
    }
    .bsSliderInter .carousel-elem-box-img img{
        width: 18vw;
        height:18vw;
    }
    .bsSliderInter .carousel-elem-box-text{
        align-items: flex-start;
    }
  }

@media screen and (min-width: 400px) {

}


@media screen and (min-width: 650px){
    .bs-subsection-elem--value-cards{
        flex-direction: row-reverse;
    }
}

@media screen and (min-width: 600px) {
    .bsSliderInter .carousel-elem-box {
        width: 50vw !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bsSliderPart .carousel-elem-box-img{
        padding:0px;
        width: 32vw;
    }
    .bsSliderPart .carousel-elem-box-img img{
    width: 80%;
    height:auto;
    }

    .bsSliderPart .container{
        width: 80% !important;
        justify-content: space-between;
    }
    .bsSliderPart{
        margin:20px 0px !important;
    }
    .bsSliderPart{
        height:10vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bs-subsection-elem--value-card{
        padding:20px;
    }
    .hs-slider-banner-container{
        height:400px !important;
    }
    .hs-slider-container .carousel-indicators li{
        width: 9px !important;
        height:9px !important;
    }
    .hs-slider-banner-content  .des-text{
        top: 25%;
        right: 30px;
        width: 60%;
        z-index: 10;
    }
    .hs-slider-banner-content  .des-text span{
        font-size: 35px;
    }
}
.hs-navbar--left-side{
    display: flex;
    align-items: center;
    gap:20px;
}
.hs-navbar--left-side a{
    text-decoration: none;
    font-size:16px;
    color:black;
}

.hs-navbar--left-side .hs--desk-actual-nav-menu{
    display: flex;
    align-items: center;
    gap:20px;
}
.hs-navbar {
    font-weight: bold;
}
@media screen and (min-width: 700px) {

    .bsSliderInter .container{
        gap:20px;
    }
    .bsSliderPart{
        height:10vw;
    }
}

@media screen and (min-width: 700px) {
    .bsSliderInter .container{
        gap:40px;
    }
    .Values--cards-cont{
        padding:20px 0px;
    }
    .bs-subsection-elem-cards-container{
        padding:20px 0px;
    }
}

@media screen and (min-width: 800px) {
    .bsSliderInter .container{
        gap:40px;
    }
    .bs-cards-content{
        width: 200px;
        height:200px;
    }
    .bs-cards-content img{
        width: 50%;
        height:50%;
    }
    .bsSliderInter .container{
        gap:20px;
    }
    .bsSliderInter .carousel-elem-box{
        height:28vw !important;
    }

    .bs-subsection-elem-title span{
        font-size: 40px !important;
    }
    .bs-subsection-elem-desc span{
        font-size: 20px !important;
    }
    .bsSliderInter{
        margin-top:20px;
    }
    .hs-slider-banner-container{
        height:500px !important;
    }
    .footer-content{
        padding:30vw 30vw 20vw 30vw;
    }
    .bsSliderInter .carousel-elem-box-img{
        width: auto !important;
        height:auto !important;
        padding:10px;
    }
    .bsSliderInter .carousel-elem-box-img img{
        width: 15vw;
        height:15vw;
    }
    .bs-subesction-content{
        gap:70px;
    }
    .copyright-terms-container{
        flex-direction: row !important;
        justify-content: space-between;
        /*gap:20px;*/
        padding:20px 10vw !important;
    }
    
}

@media screen and (min-width: 600px) {
    .bs-subsection-elem-title span{
        font-size: 30px;
    }
    .bs-subsection-elem-desc span{
        font-size: 16px;
    }
    .hs-logo--container{
        width: 15vw !important;
    }
    .footer-content{
        padding:10vw;
    }

}

@media screen and (min-width: 900px) {
    .bs-wrapper-sub--elem-upper-section{
        flex-direction: row;
    }
    .bs-wrapper-sub--elem{
        width: 100%;
        
    }
    .sub-logo--container{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hs-logo--container{
        width: 210px !important;
    }
    .bs-subsection-elem--value-cards{
        justify-content: space-between;
       
    }
    .Values--cards-cont{
        padding:20px 60px !important;
    }
    .bs-subsection-elem--value-cards .bs-subsection-elem--value-card{
        width:420px !important;
    }
    .footer-content{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .footer-elem-body-cols-container{
        gap:40px;
    }
    .bs-wrapper-sub--elem-content, .bs-wrapper-sub-hirStruct{
        height: 100%;
    }
    .hs-slider-banner-content .art-container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items:center;
        justify-content: flex-end;

    }
}

@media screen and (min-width: 1000px) {
    .footer-content{
        display: flex;
        flex-direction: row;
    }
    .footer-elem{
        width:300px;
    }
    .footer-content{
        padding:20px !important;
        align-items:flex-start;
        justify-content: center;
    }
    .bsSliderPart .carousel-elem-box-img{
        width: auto !important;
    }
    .bsSliderInter .carousel-elem-box-text span{
        font-size: 19px;
    }
    .des-text{
        width: 45% !important;
        top:100px !important;
    }
    .des-text span{
        font-size: 60px !important;
        font-weight:700;
    }
}

@media screen and (min-width: 1200px) {
    .bsSliderInter .carousel-elem-box{
        height:25vw !important;
    }
    .hs--mob-sandwich-menu-icon-search-cont{
        display: none !important;
    }
    .reb-btn{
        background: #EA0029 !important;
        color:white;
        outline: none !important;
        border: none !important;
        border-radius: 10px;
        -webkit-box-shadow: 10px 10px 25px -16px rgba(0,0,0,0.67);
-moz-box-shadow: 10px 10px 25px -16px rgba(0,0,0,0.67);
box-shadow: 10px 10px 25px -16px rgba(0,0,0,0.67);
font-weight: bold;
    }
    .reb-btn:hover{
        background: #EA0029 !important;
        color:white;
    }
    .reb-btn:focus{
        background: #EA0029 !important;
        color:white !important;
    }


    .hs-navbar-links-btns-desk--ver{
        display: flex;
        align-items: center;
        gap:15px;
    }
    .gray-btn{
        background: #5A5A5A;
        width: 35px;
        height:35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
    }
    .gray-btn img{
        width:21px !important;
        height:21px !important;
    }
    .hs-navbar{
        padding:40px 20px;
    }

    .bs-subsection-elem-cards-content{
        gap:120px;
    }
    .hs--desk-actual-nav-menu{
        gap:15px !important;
    }
    .hs-navbar-links-btns-desktop--ver{
        display: block !important;
    }
    .hs-navbar-links-btns-desk--ver{
        display: flex !important;
        flex-direction: row-reverse;
        align-items: center;
        gap:20px;
    }
    .footer-content{
        padding:50px !important;
        align-items:flex-start;
        justify-content: space-between;
    }
    .footer-elem{
        width:30vw;
    }
    .Values--cards-cont{
        margin-bottom:100px !important;
    }
    .bsSliderInter .carousel-control-next, .bsSliderInter .carousel-control-prev{
        width:6%;
    }
    .bs-cards-content{
        width: 260px;
        height:260px;
    }
    .bs-subsection-elem-cards-content{
        gap:10vw !important;
    }
    .bs-subesction-content{
        gap:100px;
    }
    .bs-subsection-elem-desc:nth-child(2){
        margin-bottom: 30px;
    }
    .bs-subsection-elem:nth-child(1){
        padding:0px 40px;
    }

}

@media screen and (min-width: 1300px) {
    .hs-navbar--left-side{
        gap:40px;
    }
    .hs-navbar{
        padding:60px;
    }
    .bs-subsection-elem-wrapper{
        margin: 60px 80px;
    }
    .des-text{
        right:60px !important;
    }
    .bsSliderInter .carousel-elem-box{
        width: 349px !important;
        height: 300px !important;
    }
    .bsSliderInter .carousel-elem-box .carousel-elem-box-img img{
        width: 202px !important;
        height:202px !important;
    }
    .footer-content{
        width: 1250px !important;
        padding-left:0px !important;
        padding-right:0px !important;
        flex-wrap: nowrap !important;

    }
    .footer-container{
        display: flex;
        justify-content: center !important;
        align-items: center !important;

    }
    .subsection--white .bsSliderPart{
        -webkit-box-shadow: 10px 3px 51px -42px rgba(0,0,0,0.67);
-moz-box-shadow: 10px 3px 51px -42px rgba(0,0,0,0.67);
box-shadow: 10px 3px 51px -42px rgba(0,0,0,0.67);
    
    }

    .subsection--white{
        padding:40px 0px !important;
    }
    .subsection--white .bs-subesction-content{
        padding:0px !important;
    }
    .copyright-terms-container{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 1200px !important;
        gap:230px !important;
        padding:20px 0px !important;
    }
    .hs-slider-banner-content img{
        height: 45% !important;
    }
}

.hs-navbar-links-btns-desktop--ver, .hs-navbar-links-btns-desk--ver{
    display: none;
}

@media screen and (min-width: 1440px) {
    .body-section .bs-subsection{
        padding:40px 20px;
    }
    .bsSliderInter .carousel-elem-box{
        width: 280px !important;
        height: 320px !important;
    }
    .bsSliderPart{height: 170px;}
    .bs-subsection-elem--value-card{
        height:238px;
    }
    .art-icon-cont{
        width: 140px;
        height:140px;
    }
    .bs-subsection-elem--value-card{
        width: 522px;
        padding:40px;
    }
    .subsection--gray{
        padding-top:100px !important;
    }

}
@media screen and (min-width: 1600px) {
    .body-section .bs-subsection-elem:nth-child(1){
        padding:40px 90px;
    }
    .bs-subsection-elem-wrapper{
        width: 1200px;
    }
    .bs-subsection-elem{
        width: 1500px;
    }
    .subsection--white .bs-subsection-elem:nth-child(2){
        width: 100% !important;
    }
    .subsection--gray .bs-subsection-elem:nth-child(2){
        width: 100% !important;
    }
    .bsSliderInter .container{
        justify-content: space-between;
    }
    .hs-slider-banner-content img{
        height: 40% !important;
    }
}

@media screen and (min-width: 1800px){
    .hs-slider-banner-content img{
        height: 45% !important;
    }
    .hs-slider-banner-container{
        height:700px !important;
    }
}


.container{
    max-width: 100% !important;
}

.terms-content{
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: flex-end;
}

.copyright-terms-container{
    width: 100%;
    display: flex;
    align-items: center;
    /*justify-content: flex-end;
    width: 1200px;
    gap:140px;*/
    gap:20px;
    flex-direction: column;
}

.footer-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
}
.copyright-terms-container {
    padding: 20px 0px;
}
.copyright-terms-container span{
    color: white;
}
.terms-content a:hover{
    text-decoration: none;
}
.hs--mob-sandwich-menu-icon-search-cont{
    display: flex;
    gap:20px;
    align-items: center;
    justify-content: center;
}
.hs--mob-search-icon-cont, .hs--mob-sandwich-menu-icon-cont{
    width: 18px;
}
.hs--mob-search-icon-cont svg, .hs--mob-sandwich-menu-icon-cont img{
    width: 100%;
    height:100%;
}
.hs--mob-sandwich-menu-icon-content{
    display:flex;
    flex-direction: column;
    align-items: center;
    width: 20px;
    gap: 5px;
}
.hs--mob-sandwich-menu-horz-bar{
    width: 100%;
    height:2px;
    background-color: black;
    border-radius: 12px;
}
.hs--mob-search-icon-cont:hover, .hs--mob-sandwich-menu-icon-cont:hover{
    cursor:pointer;
}
.hs-navbar--right-si{
    position: relative;
}

/*Menu dropdown Styling*/
.menu-dropdown--mob-ver{
    position: absolute;
    top: 30px;
    left:0;
    width: 300px;
    background: white;
    z-index: 2000;
    border-radius: 15px;
    -webkit-box-shadow: 10px 7px 38px -24px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 7px 38px -24px rgba(0,0,0,0.75);
    box-shadow: 10px 7px 38px -24px rgba(0,0,0,0.75);
   
}
.user-icon-connect {
    width: 18px;
}
.user-icon-connect svg{
    width: 100%;
    height:100%;
}
.menu-dropdown--mob-ver-content-header{
    display:flex;
    align-items: center;
    gap:15px;
}
.menu-dropdown--mob-ver-content-header{
    padding: 20px 30px;
    border-bottom: black 1px solid;
}
.menu-dropdown--mob-ver-content-header:hover span{
    color:#EA0029 !important;
}
.menu-dropdown--mob-ver-content-header:hover svg image{
   background:#EA0029 !important;
}
.menu-dropdown--mob-ver-content-body{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    gap:15px;
    padding:20px 30px;
}
.menu-dropdown--mob-ver-content-body a{
    font-weight: 500;
    color: black;
}
.menu-dropdown--mob-ver-content-body a:hover{
    text-decoration: none;
    color:#EA0029;
    cursor: pointer;
}
.menu-dropdown--mob-ver-content-header span:hover{
    cursor: pointer;

}

#search-modal .header-search-box .header-search-box-content{
    width: 100%;
}
#search-modal .modal-dialog{
    padding-top:35px;
}

.hs--mob-search-icon-cont button {
    border: none;
}

@media screen and (max-width: 1000px){
    .art-container{
        display: flex !important;
        justify-content: flex-end;
    }
    .hs-slider-banner-container {
        height: 120px !important;
    }
}


@media screen and (max-width: 600px){
    .hs-slider-banner-content .des-text{
        width: 50%;
    }
}