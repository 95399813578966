.admin-dashboard--nav{
    width: 100%;
}
/*

*/
.admin-dashboard--nav-content{
    display:flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.admin-dashboard--nav-elem{
    display: flex;
    align-items: center;
    gap: 15px;
}
.admin-dashboard--nav-elem svg{
    width: 28px;
}
.admin-dashboard--nav-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:10px;
}
.admin-dshb-courses-manager-gest .admin-dashboard--nav{
    padding: 20px;
}

.admin-dashboard--nav-content{
    background: white;
    padding: 20px;
    border-radius: 10px;
}
.admin-dashboard--nav-elem{
    padding:5px 20px;
    font-weight: 700;
}
.active-dshb-nav-elem{
    background: black;
    border-radius: 10px;
    width: 100%;
    display: flex;
    padding:5px 20px;

}
.active-dshb-nav-elem span{
    color:white;
}
.active-dshb-nav-elem svg g{
    stroke:white;
}
.active-dshb-nav-elem svg path{
    fill:white;
}

.active-dshb-nav-elem .icon-cont svg path{
    stroke:white;
}
.dashboard-row .header-search-box-content{
    height:100%;
    
}
/*
.dashboard-row .header-search-box{
    width: 80%;
}*/
.dashboard-row .header-search-box{
    width: 100%;
}
.dashboard-row .header-search-box-content{
    padding:6px 20px;
}
.dashboard-row .header-search-box .search-input-container input{
    font-size: 15px;
}



.dashboard-row .header-search-box-content{
    width: 100%;
}
.admin-dashboard-view-MultiRow .dashboard-row:nth-child(2){
    gap:20px;
}
.admin-dashboard-view-MultiRow .dashboard-row:nth-child(2) button{
    justify-content: center;
}

.dashboard-row .cards-container{
    padding: 0px;
}
.dashboard-row .cardes-wrapper-content{
    gap:30px;
}

.dashboard-row .card-wrapper-elem-content{
    width:250px;
    height:250px;
}
.dashboard-row .cards-wrapper-img{
    width: 150px;
    height:150px;
}
.dashboard-row .course-card-title span{
    font-size: 16px;
}
.admin-view-dashboard .dashboard-row .btn-red{
    padding:3px 30px !important;
}



@media screen and (min-width: 800px) {
    .admin-dashboard-view-MultiRow .dashboard-row:nth-child(2){
        flex-direction: row;
    }
    .dashboard-row .header-search-box{
        width: calc(100% - 216px);
    }
    .admin-dashboard-view-MultiRow .multi-btns--ver .header-search-box{
        width: calc(100% - 420px);
    }
    .admin-dashboard--nav-content, .admin-dashboard--nav-elem{
        height:100% !important;
    }
    .dashboard-row .admin-dshb-user-container{
        width: 100%;
    }
}
.admin-dshb-files-sidenav-mob .admin-dshb-user-elem{
    display: flex;
    flex-direction: column;
    gap:20px;
}

@media screen and (min-width: 900px) {
    .dashboard-row .cardes-wrapper-content{
        gap:30px !important;
    }
    .admin-dashboard--nav-elem span{
        font-weight: 700;
        display: block;
    }
    .admin-dashboard--nav-content{
        background: white;
        display:flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        border-radius: 10px;
        -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
        -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
        box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    }
    .admin-dashboard--nav-content{
        background: none;
        padding: 0px;
    }
    .admin-dashboard--nav-elem{
        width:100%;
        padding: 3px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    .admin-dashboard--nav-elem svg{
        width: 22px;
    }
    .admin-dashboard--nav-elem span{
        font-size: 13px;
    }
    .admin-dashboard-view-MultiRow .cards-container{
        width: 100%;
        padding:30px 20px;
        background: white;
        border-radius: 8px;
        -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
        -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
        box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    }
    .active-dshb-nav-elem{
        padding:0px;
    }
    .admin-dashboard--nav-content{
        background: white;
    }
}
.icon-cont{
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-cont svg{
    width: 80%;
    height:80%;
}

@media screen and (min-width:900px) {
    .dashboard-row .cardes-wrapper-content{
        width: 820px;
    }
    .dashboard-row .cardes-wrapper-content{
        justify-content: flex-start;
    }
    /*
    .dashboard-row .cards-wrapper-elem{
        width: 30%;
    }
    .dashboard-row .cardes-wrapper-content{
        gap:4.3vw !important;
    }*/
}

@media screen and (min-width: 1000px) {
    /*
    .dashboard-row .cardes-wrapper-content{
        width: 920px;
        gap:38px !important;
    }
    */
    .dashboard-row .cards-wrapper-elem, .dashboard-row .card-wrapper-elem-content{
        width: 280px;
        height:280px;
    }
    .dashboard-row .cards-container{
        padding-top:30px;
    }
    .dashboard-row .cards-wrapper-img{
        width: 180px;
        height:180px;
    }
    .dashboard-row .cards-wrapper-text span{
        font-size: 18px;
    }
}

@media screen and (min-width: 1100px) {
    .admin-dashboard--nav-elem svg{
        width: 28px;
    }
    .admin-dashboard--nav-elem span{
        font-size: 14px;
    }
}

@media screen and (min-width: 1200px) {
    .dashboard-row .cardes-wrapper-content{
        gap:40px !important;
    }
}

@media screen and (min-width: 1300px) {
    .-dshb-search--bar{
        margin-top: 40px;
    }
    .admin-view-dashboard .dashboard-content--ad {
        gap: 20px !important;
    }
    .admin-view-dashboard .admin-dashboard--nav-elem span{
        font-size: 16px;
    }
    .admin-view-dashboard .admin-dashboard--nav-elem{
        padding:5px 0px;
    }
    .admin-view-dashboard .dashboard-row .btn-red{
        padding:8px 30px !important;
    } 
}

@media screen and (min-width: 1366px) {
    .dashboard-row .cardes-wrapper-content{
        width: 1000px;
        gap:80px !important;
    }
}


/*
@media screen and (min-width: 1440px) {
    .admin-dashboard--nav-elem span{
        font-size: 16px;
    }
    .dashboard-row .cards-wrapper-elem, .dashboard-row .card-wrapper-elem-content{
        width: 280px !important;
        height:280px !important;
    }
    .dashboard-row .cards-wrapper-img{
        width: 180px !important;
        height:180px !important;
    }   
} */

/*
@media screen and (min-width: 1600px) {
    .admin-dashboard--nav-elem span{
        font-size: 16px;
    }
    .dashboard-row .cards-wrapper-elem, .dashboard-row .card-wrapper-elem-content{
        width: 230px !important;
        height:230px !important;
    }
    .dashboard-row .cards-wrapper-img{
        width: 130px !important;
        height:130px !important;
    }
    .dashboard-row .cardes-wrapper-content{
        width: 1100px;
        gap:60px !important;
    }

} */


/*
@media screen and (min-width: 1700px) {
    .admin-dashboard--nav-elem span{
        font-size: 16px;
    }
    .dashboard-row .cards-wrapper-elem, .dashboard-row .card-wrapper-elem-content{
        width: 250px !important;
        height:300px !important;
    }
    .dashboard-row .cards-wrapper-img{
        width: 180px !important;
        height:180px !important;
    }
    .dashboard-row .cardes-wrapper-content{
        width: 1100px;
        gap:30px !important;
    }
    .admin-dashboard--nav-elem span{
        font-size: 18px;
    }
} */


.admin-view-dshbd-main-sect .side-menu-content{
    height:auto !important;
}
a:hover{
    text-decoration: none;
}
.course-summary-header-elem-edit-sect{
    display: flex;
    align-items: center;
}
.course-summary-elem-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.course-summary-header-elem-edit-sect{
    display: flex;
    align-items: center;
    gap:10px;
    padding:0px 15px;
}
.course-summary-header-elem-edit-sect button{
    border: none;
    background: none;
}

.admin-dashboard-view-MultiRow .course-info-desc{
    width: 100%;
}
.admin-dashboard-view-MultiRow .course-summary-content {
    width: 100%;
    box-shadow:none;
    background: none;
}

@media screen and (min-width: 900px){
    .admin-dashboard-view-MultiRow .course-catalogue-content{
        padding:30px;
        background: white;
        border-radius: 12px;
        -webkit-box-shadow: 3px 3px 9px 1px rgb(0 0 0 / 22%);
        -moz-box-shadow: 3px 3px 9px 1px rgba(0,0,0,0.22);
        box-shadow: 3px 3px 9px 1px rgb(0 0 0 / 22%);
    }
}


.admin-dshb-courses-manager .admin-dshb-course-name{
    justify-content: flex-start !important;
}

.admin-dashboard--nav-content a{
    width: 100%;
    height:100%;
    color:black;
}
@media screen and (min-width: 1300px){
    .admin-dshb-courses-manager-gest .admin-dashboard--nav {
        padding:0px;
    }
}
@media screen and (max-width: 650px){
.admin-dashboard-view-content .course-general-info{
height:350px;
}}
.admin-dshb-courses-manager .checkmark {
    border: 2px #FD6150 solid;
}

.admin-dashboard-view-MultiRow .course-summary-content{
    padding:0px;
}
.course-miniature{
    width: 150px;
    /* height:150px; */
}
.admin-dashboard-view-MultiRow .course-miniature img{
    border-radius: 50%;
    width:100%;
    height:100%;
}
.course-min-edit-btn button{
    background: none;
    border: none;
}
.admin-dashboard-view-MultiRow .course-general-info-content{
    padding:15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.admin-dashboard-view-MultiRow .course-info-regist-container{height:auto; padding:0px;}
.admin-dashboard-view-MultiRow .course-info-regist-content,
.aadmin-dshb-courses-manager-gest .course-info-regist-content{
    padding-top:0px;
}
.admin-dashboard-view-MultiRow .course-info-regist-container,
.admin-dashboard-view-MultiRow .course-min-edit-btn{
    width: 100%;
}
.admin-dashboard-view-MultiRow .course-info-ratings{
    gap:15px;
}
.admin-dshb-user-name-icon-cont{
    width:38px;
    height:38px;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin-dshb-user-name-icon-cont img{
    width:50%;
    height:50%;
    object-fit: contain;
}
.admin-dshb-user-progress-bar{
    width: 90%;
    background: #DEE0E6;
    position: relative;
    height: 17px;
    border-radius: 30px;
}
.admin-dshb-user-actual-progress{
    position: absolute;
    background: #EA0029;
    width: 60%;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
}
.admin-dshb-user-actual-progress span{
    color: white;
    font-size: 13px;
    font-weight: 700;
}
.admin-dshb-user-name{
    display: flex;
    align-items: center;
    gap:30px;
}
.admin-dshb-user-elem-content{
    display: flex;
    flex-direction: column;
    gap:10px;
    width: 100%;
}
.admin-dshb-user-elem{
    display: flex;
    gap:10px;
    background: #FBFBFB;
    padding:20px;
    border-radius: 8px;
    -webkit-box-shadow: 2px 7px 10px -9px rgb(0 0 0 / 32%);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgb(0 0 0 / 32%);
}
.admin-dshb-user-body-content{
    display: flex;
    flex-direction: column;
    gap:10px;
}
.admin-dshb-user-body{
    display: none;
}
.admin-dshb-user-body, .admin-dshb-user-body--mob-ver{
    background: white;
    border-radius: 8px;
}
.admin-dshb-user-elem-edit-btn{
    width:20px;
    height:20px;
}
.admin-dshb-user-elem-edit-btn button{
    border: none;
    background: none;
}
.admin-dshb-user-elem-edit-btn svg{
    width:100%;
}
.admin-dshb-user-name span{
    font-weight: 600;
    font-size: 16px;
}
.admin-dshb-user-start-date span, .admin-dshb-user-end-date span{
    font-weight: 600;
}
.admin-dshb-user-header-elem span{
    color:white;
    font-weight: 700;
}
.dashboard-row .-option--btn{
    width: 80px !important;
}
.admin-dshb-user-header-content .dashboard-row .-option--btn{
    width: 80px !important;
}
.admin-dshb-user-more-action-sect button{
    border: none !important;
    background: none !important;
}
.admin-dshb-user-body--mob-ver .admin-dshb-user-more-action-sect{
    display: flex;
    gap:10px;
}

.admin-dshb-course-file-size span{
    font-weight: 700;
}
.dnd-icon-cont{
    width: 50px;
    height:50px;
}
.dnd-icon-cont svg{
    width: 100%;
    height:100%;
}
.drag-drop-zone-sect{
    background: #FBFBFB;
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:30px;
    gap:30px;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
        -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
        box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
}
.drag-drop-zone-sect span{
    display: none;
}
.admin-dshb-user-header{display: none;}
@media screen and (min-width: 550px) {
    
}

@media screen and (min-width: 600px) {
    .drag-drop-zone-sect span{
        display: block;
        font-weight: 700;
    }
}
@media screen and (min-width: 900px) {
    .admin-dshb-user-body{display: block;} 
    .admin-dshb-user-body--mob-ver{display: none;}
    .admin-dshb-user-container{
        width: 100%;
    }
    .admin-dshb-user-body .admin-dshb-user-elem-content{
        flex-direction: row;
        align-items: center;
        justify-content: space-between;    
    }
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-user-name,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-user-progress,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-user-start-date,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-user-end-date,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-course-files,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-course-file-size,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-user-more-action-sect,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-user-lvl,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-user-badges,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-user-score,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-user-completed-courses,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-user-type,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-course-type,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-completed-courses,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-other-courses,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-course-name,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-badge-name,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-badge-type,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-earned-badges,
    .admin-dshb-user-body .admin-dshb-user-elem-content .admin-dshb-other-badges{
        width: 100%;   
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .admin-dshb-user-more-action-sect button{
        border: none !important;
        background: none !important;
    }
    .admin-dshb-user-more-action-sect{
        display: flex;
        gap:10px;
        justify-content: space-between;
    }
    .admin-dshb-user-elem-edit-btn{
        width: 80px;
    }
    .admin-dshb-user-header-elem svg{
        width: 25px;
    }
    .admin-dshb-user-header{
        display: block;
        margin-bottom: 30px;
        -webkit-box-shadow: 2px 7px 10px -9px rgb(0 0 0 / 50%);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.50);
    box-shadow: 2px 7px 10px -9px rgb(0 0 0 / 50%);
    }
    .admin-dshb-user-header-content{
        background-color: #5A5A5A;
        padding:10px 15px;
        display:flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;
    }
    .admin-dshb-user-header-content .admin-dshb-user-header-elem{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.admin-dshb-course-files .file-type-cont{
    width: 40%;
    background:#EA0029;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding:2px 0px;
}
.file-type-cont span{
    font-size: 16px;
    font-weight: 700;
    color:white;
}
@media screen and (min-width: 1200px) {
    .admin-dshb-user-elem{
        padding:10px 20px;
    }
}
.dashboard-row .btn-green{
    font-size: 16px !important;
    border-radius: 8px;
}
.admin-view-dashboard .btn-green{
    margin-top: 30px;
}
.plus-sign{
    font-size: 30px !important;
    color:#00732F;
    font-weight: 700;
    width: 10%;
    text-align: center;
}
.-empty--header-elem{
    width: 10% !important;
}

.admin-dshb-general-stats{
    background: white;
    border-radius: 10px;
}
.admin-dshb-general-stats-elem-title{
    font-weight: 700;
    font-size: 16px;
}
.user-stat-vals{
    font-weight: 700;
    font-size: 25px;
    color:#00732F;
}
.admin-dshb-general-stats-elem{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:20px;
}
.admin-dshb-general-stats-sect{
    display: flex;
    flex-direction: column;
    gap:10px;
    padding:15px;
}
.admin-dashboard-view-stats-graph-controls{
    display: flex;
    align-items: center;
    gap:10px;
}
.admin-dashboard-view-stats-graph-controls button{
    border: none;
    background: none;
    width: 30px;
    height:30px;
}
.admin-dashboard-view-stats-graph-controls button svg{
    width: 100%;
    height:100ù;
}
.admin-dashboard-view-stats-actual-graph{
    width:100%;
    height:400px;
}
.admin-dashboard-view-stats-graph{
    padding:20px;
    background: white;
    border-radius: 10px;
}
.admin-dashboard-view-stats-actual-graph-content{
    background: coral;
    width: 100%;
    height:100%;
}
.admin-dashboard-view-stats-graph-content{
    display: flex;
    flex-direction: column;
    gap:15px;
}
.pie-chart-legend-color{
    width: 15px;
    height:15px;
    border-radius: 50%;
}
.--black-ver{
    background: black;
}
.--black-ver-sect{
    color: black !important;
}
.--red-ver{
background: #EA0029;
}
.--red-ver-sect{
    color: #EA0029 !important;
    }

.--green-ver{
    background: #00732F;
}
.--green-ver-sect{
    color: #00732F !important;
}
.--gray-ver{
        background: #5A5A5A;
}
.--gray-ver-sect{
    color: #5A5A5A !important;
}
.admin-dashboard-view-stats-actual-pie-chart-legend-elem{
    display: flex;
    align-items: center;
    gap:20px;
}
.admin-dashboard-view-stats-actual-pie-chart{
    width: 150px;
    height:150px;
}
.admin-dashboard-view-stats-actual-pie-chart svg{
    width: 100%;
    height: 100%;
}
.admin-dashboard-view-stats-pie-chart-content, .admin-dashboard-view-stats-pie-chart-body{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:20px;
}
.admin-dashboard-view-stats-pie-chart{
    background: white;
    padding:20px;
    border-radius: 10px;
}
.admin-dashboard-view-stats-actual-pie-chart-legend{
    display: flex;
    flex-direction: column;
    gap:10px;
}
.admin-dashboard-view-stats-pie-chart-content span{
    font-weight: 700;
}

@media screen and (min-width: 600px) {
    .admin-dashboard-view-stats-pie-chart-body{
        flex-direction: row;
        gap:40px;
    }
    .admin-dashboard-view-stats-pie-chart-content{
        gap:30px;
    }
}
.admin-dashboard-view-stats-pie-chart-content--desktop-ver{
    display: none;
}
@media screen and (min-width: 800px) {
    .admin-dashboard-view-stats-pie-chart, .admin-dashboard-view-stats-graph{
        width: 100%;
    }
    .admin-dashboard-view-stats-actual-graph{
        width:100%;
        height:300px;
    }
    .admin-dshb-general-stats--vertSep{
        width: 3px;
        height:70px;
        background:#00732F;
    }
    .admin-dshb-general-stats-sect{
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding:30px;
        align-items: center;
    }
    .admin-dshb-general-stats-elem{
        flex-direction: column;
    }
    .admin-dashboard-view-stats-pie-chart-content{
        display: none;
    }
    .admin-dashboard-view-stats-pie-chart-content--desktop-ver{
        display: flex;
        flex-direction: column;
        gap:40px;
        font-weight: 700;
    }
    .admin-dashboard-view-stats-pie-chart-content--desktop-ver .admin-dashboard-view-stats-actual-pie-chart-legend{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        width: 90%;
    }
    .admin-dashboard-view-stats-pie-chart-content--desktop-ver .admin-dashboard-view-stats-actual-pie-chart-legend-elem-col{
        display: flex;
        flex-direction: column;
        gap:30px;
    }
    .admin-dashboard-view-stats-pie-chart-content--desktop-ver .admin-dashboard-view-stats-pie-chart-header{
        display: flex;
        justify-content: center;
        font-size: 22px;
    }
}
@media screen and (min-width: 1000px) {
    .admin-dshb-general-stats-sect{
        padding:30px 50px;
    }
    .admin-dashboard-view-stats-pie-chart-content--desktop-ver{
        padding:30px 40px;
    }
    .admin-dashboard-view-stats-actual-pie-chart-legend-elem{
        font-size: 18px !important;
    }
}
.admin-dashboard-view-stats-actual-pie-chart{
    position: relative;
}
.admin-dashboard-view-stats-actual-pie-chart span{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px !important;
}
.admin-dshb-checkbox{
    display: flex;
    align-items: center;
    gap:20px;
}
.admin-dshb-checkbox-content{
    width: 20px;
    height: 20px;
}
label{margin: 0px !important;}
.checkbox-header-elem{
    justify-content: flex-start !important;
}
.admin-dshb-course-name{
    width: 100%;
}

.admin-dshb-courses-manager .admin-dshb-user-header-elem{
width: 300px !important;
}
.admin-dshb-courses-manager .admin-dshb-course-type{
    display:flex;
    justify-content: center;
}
.admin-dshb-courses-manager .admin-dshb-course-name,
.admin-dshb-courses-manager .admin-dshb-course-type{
    width: 300px !important;
    font-weight: 700 !important;
}

/*
.admin-dshb-courses-manager .admin-dshb-user-more-action-sect*/


.admin-dshb-courses-manager .checkbox-header-elem{
    color:white;
    font-weight: 700;
    padding-right:5px;
}
.admin-dshb-courses-manager .admin-dshb-user-elem{
    padding: 30px 20px;
}
.admin-dshb-courses-manager .-dshb-search--bar{
    margin: 0px 0px 20px 0px;
}
.admin-dshb-courses-manager .admin-dshb-user-more-action-sect{
    width: 300px !important;
}
.user-type-icon-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}




/* The container */
.admin-dshb-courses-manager  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .admin-dshb-courses-manager  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .admin-dshb-courses-manager  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px black solid;
    background: none;
    border-radius: 3px;
  }

  
  /* When the checkbox is checked, add a blue background */
  .admin-dshb-courses-manager  .container input:checked ~ .checkmark {
    background-color: none;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .admin-dshb-courses-manager  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .admin-dshb-courses-manager  .container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 6px;
    height: 10px;
    border: solid black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }


/* The container */
.admin-dshb-user-header-elem .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .admin-dshb-user-header-elem .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .admin-dshb-user-header-elem .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px white solid;
    background: none;
    border-radius: 3px;
  }

  
  /* When the checkbox is checked, add a blue background */
  .admin-dshb-user-header-elem .container input:checked ~ .checkmark {
    background-color: none;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .admin-dshb-user-header-elem .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .admin-dshb-user-header-elem .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .admin-dshb-user-header-elem .container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  @media screen and (max-width: 900px) {
    .admin-dshb-courses-manager .admin-dshb-user-elem,
    .admin-dshb-courses-manager-gest .admin-dshb-user-elem{
        flex-direction: column;
        gap:20px;
    }
    .admin-dshb-courses-manager .admin-dshb-user-more-action-sect{
        flex-direction: row;
    }
  }

  @media only screen and (max-width: 1200px) and (min-width: 800px)  {
    .admin-dshb-courses-manager .header-search-box{
        width: calc(100% - 460px) !important;
    }
  }

  @media screen and (max-width: 800px) {
    .admin-dshb-courses-manager .admin-dshb-user-more-action-sect,
    .admin-dshb-courses-manager .admin-dshb-course-name, 
    .admin-dshb-courses-manager .admin-dshb-course-type{
        width:auto !important;
    }
    .admin-dshb-courses-manager .admin-dshb-user-elem-content{
        width: 80%;
    }
    .admin-dshb-courses-manager .admin-dshb-course-type{
        justify-content: flex-start;
    }
}


.admin-dshb-courses-manager-gest .admin-dshb-user-header-content{
    padding:10px 0px;
}
.admin-dshb-courses-manager-gest .-dshb-search--bar{
    margin-top: 0px;
}
.user-types-access-actual-stat-icon-cont{
    width: 150px;
    height:150px;
    border-radius: 50%;
    border: 3px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-types-access-actual-stat{
    position: relative;
}
.stat-number-label{
    position: absolute;
    margin: 0px auto auto;
    top: -15px;
}
.user-types-access-actual-stat{
    display: flex;
    justify-content: center;
}
.stat-number-label span{
    font-size: 20px;
}
.stat-number-label {
    padding:2px 15px;
    background: white;
}
.user-types-access--stats-elem{
    padding:20px;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap:35px;
    align-items: center;
    font-weight: 700;
    width: 100%;
}
.user-types-access--stats-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px;
}
@media screen and (min-width: 1300px) {
    .user-types-access--stats-content{
        flex-direction: row;
        justify-content: space-between;
    }
    .user-types-access-actual-stat-icon-cont{
        width: 100px;
        height:100px;
    }
    .right-sect-dshbd{
        width:100% !important;
    }
    .right-sect-dshbd .user-types-access--stats{
        width:100% !important;
    }

}


@media screen and (min-width: 900px) {
    .admin-dshb-courses-manager-gest .dashboard-column {
        width: 50% !important;
    }
    .admin-dshb-courses-manager-gest .dashboard-content {
        padding:0px;
    }
    .admin-dshb-courses-manager-gest .admin-dashboard-view-content{
        padding:0px 20px;
    }
}
@media screen and (min-width: 1300px) {
    .admin-dshb-courses-manager-gest .dashboard-content--ad {
        padding:0px 0px 0px 60px;
    }
    .admin-dshb-courses-manager-gest .right-sect-dshbd{
        width: calc( 100% - 332px ) !important;
    }
    .admin-dshb-courses-manager-gest .admin-dashboard-view-content{
        padding:0px 0px 0px 60px;
    }
}
@media screen and (min-width: 1600px) {
    .admin-dshb-courses-manager-gest .admin-dashboard-view-content{
        padding:0px 0px 0px 100px;
    }
}
@media screen and (min-width: 1750px) {
    .admin-dshb-courses-manager-gest .admin-dashboard-view-content{
        padding:0px 0px 0px 140px;
    }
}
@media screen and (min-width: 1750px) {
    .admin-dshb-courses-manager-gest .admin-dashboard-view-content{
        padding:0px 0px 0px 160px;
    }
}

.admin-dshb-users-progress-sect-elem{
    display:flex;
    align-items: center;
    justify-content: space-between;
}
.admin-dshb-users-progress-inNum{
    background: #EA0029;
    width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
}
.admin-dshb-users-progress-sect span{
    font-weight: 700;
    font-size: 17px;
}
.admin-dshb-users-progress-inNum span{
    color:white;
    font-weight: 700;
    font-size: 17px;
}
.admin-dshb-users-progress-sect-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.-admin-dshb--ups{  
    height:40% !important; 
}
.admin-dshb-users-progress, .admin-dshb-users-progress-content{
    height:100%;
}
.admin-dshb-courses-progress-info{
    height:60% !important;
}
.admin-dshb-users-progress-content{
    display: flex;
    flex-direction: column;
    gap:30px;
    padding:20px;
    background: white;
    border-radius: 10px;
}
.admin-dshb-users-progress-title{
    font-weight: 700;
}
.admin-dshb-users-progress-sect-content{
    display: flex;
    flex-direction: column;
    gap:10px;
    justify-content: space-between;
}
.admin-dshb-courses-progress-info-content{
    background:white;
    border-radius: 10px;
    padding:20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap:30px;
    height:100%;
}

@media screen and (min-width: 500px) {
    .admin-dshb-courses-manager-gest .admin-dashboard-view-stats-actual-pie-chart-legend{
        flex-direction: row-reverse;
        gap:30px;
    }
    .admin-dshb-courses-manager-gest .admin-dashboard-view-stats-actual-pie-chart-legend-elem-col{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .admin-dshb-courses-manager-gest .admin-dshb-courses-progress-info-content{
        font-weight: 700;
    }
}

@media screen and (min-width: 1300px) {
    .admin-dshb-courses-manager-gest .admin-dashboard-view-stats-actual-pie-chart{
        width: 120px;
        height:120px;
    }
    .admin-dshb-courses-manager-gest .admin-dashboard-view-stats-actual-pie-chart-legend-elem-col span{
        font-size: 14px;
    }
    .admin-dshb-courses-manager-gest .left-sect-dshb{
        width: 33% !important;
    }
    .admin-dshb-courses-manager-gest .admin-dashboard-view-stats-actual-pie-chart-legend-elem{
        gap:10px;
    }
    .admin-dshb-courses-manager-gest .admin-dashboard-view-stats-actual-pie-chart-legend{
        gap:30px;
        align-items: center;
    }
    .admin-dshb-courses-manager-gest .admin-dashboard-view-stats-actual-pie-chart-legend-elem-col{
        gap:30px;
    }
    .admin-dshb-courses-manager-gest .admin-dshb-courses-progress-info-content{
        gap:40px;
        justify-content: center;
        align-items: center;
    }
    .admin-dshb-courses-manager-gest .admin-dshb-users-progress-sect,
    .admin-dshb-courses-manager-gest .admin-dshb-users-progress-sect-content{
        height: 100%;
    }
    .admin-dshb-courses-manager-gest .admin-dshb-users-progress-sect-content{
        justify-content: space-between;
    }
    .admin-dshb-courses-manager-gest .admin-dshb-courses-progress-info-title{
        font-size: 20px;
    }
}

@media screen and (min-width: 1440px) {
    .admin-dshb-courses-manager-gest .admin-view-dashboard{
        height:100%;
    }
    .admin-dshb-courses-manager-gest .left-sect-dshb{
        width: 33% !important;
    }
    .admin-dshb-courses-manager-gest .dashboard-row  .right-sect-dshbd{
        width:100% !important;
    }
    .right-sect-dshbd .user-types-access--stats{
        width:100% !important;
    }
}
@media screen and (min-width: 800px) and (max-width: 900px) {
    .admin-dshb-courses-manager-gest .dashboard-column{
        width: 100% !important;
    }
}
@media screen and (min-width: 900px) and (max-width: 1200px) {
    .admin-dshb-courses-manager-gest .dashboard-content--ad{
       gap:30px;
    }
    .admin-dshb-courses-manager-gest{
        padding:20px 0px 60px 0px;
    }
    .admin-dshb-courses-manager-gest .side-menu-content{
        height:100%;
    }
}
.--green-header-vers{
    background:#00732F;
}
.admin-dshb-user-elem-content{
    font-weight: 700;
}
.admin-dshb-badges-report .admin-dshb-user-elem,
.admin-dshb-courses-report .admin-dshb-user-elem{
    padding: 25px 20px
}

.save-btn-container .btn-green{
    margin-top: 0px !important;
}
.dashboard-row .overall-progress-stats-elem {
    background: #EA0029;
    border-radius: 12px;
}
@media screen and (min-width: 800px) and (max-width: 1000px) {
    .dashboard-sub-column{
        width: 100%;
    }
}
@media screen and (min-width: 1000px) and (max-width: 1300px) {
    .dashboard-row .profil-completion-perc{
        height: 317px;
    }
    .admin-dshb-user-info .dashboard-sub-column{
        width: 100%;
    }
}
@media screen and (min-width: 1500px) {
    .admin-dshb-user-info .dashboard-sub-column{
        width: 100%;
    }
}


.toggle-button-placeholder {
    position: relative;
}
.switch {
    position: absolute;
    display: inline-block;
    width: 40px;
    height: 22px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
#deactivated-toggle-button span {
    color: #908E8E;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(238, 238, 238);
    border-radius: 34px;
    -webkit-transition: .2s cubic-bezier(.55,0,.1,1);
    transition: .2s cubic-bezier(.55,0,.1,1);
}
.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 3px;
    bottom: 3px;
    background-color: #908E8E;
    border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: .2s cubic-bezier(.55,0,.1,1);
    transition: .2s cubic-bezier(.55,0,.1,1);
}


input:checked + .slider {
    background-color: black;
  }
  input:checked + span.slider:before {
    background-color:white;
  }

  
  input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

.empty-gamif-sys-courses-header-btn-cont,
.admin-dshb-score-settings-header-btn-cont,
.admin-dshb-score-settings-header-btns,
.admin-dshb-badges-settings-header-btn-cont,
.admin-dshb-badges-settings-header-btns,
.admin-dshb-lvl-settings-header-btn-cont,
.admin-dshb-lvl-settings-header-btns{
    display: flex;
    align-items: center;
    gap:50px;
}
.admin-dshb-score-settings-header,
.admin-dshb-badges-settings-header,
.admin-dshb-lvl-settings-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left:40px;
}
.admin-dshb-score-settings,
.admin-dshb-badges-settings,
.admin-dshb-lvl-settings{
    width: 100%;
}
.admin-dshb-score-settings-elem,
.admin-dshb-badges-settings-elem,
.admin-dshb-lvl-settings-elem{
    display: flex;
    align-items: center;
    gap:20px;
}

.admin-dshb-score-settings-elem-tag,
.admin-dshb-badges-settings-elem-tag{
    margin-right: 20px;
    font-weight: 700; 
    color:white;
    background: #FD6150;
    padding:2px 20px;
    border-radius: 30px;
}
.admin-dshb-lvl-settings-elem-tag{
    font-weight: 700; 
    color:white;
    background: #FD6150;
    padding:2px 20px;
    border-radius: 30px;
}

.admin-dshb-score-settings-body-col,
.admin-dshb-badges-settings-body-col,
.admin-dshb-lvl-settings-body-col{
    font-weight: 500;
}
.admin-dshb-lvl-settings-body-col{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.admin-dshb-score-settings-body-col .checkmark,
.admin-dshb-badges-settings-body-col .checkmark,
.admin-dshb-lvl-settings-body-col .checkmark {
    border: 2px #FD6150 solid;
}
.container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 6px;
    height: 10px;
    border: solid #FD6150;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(
45deg
);
    -ms-transform: rotate(45deg);
    transform: rotate(
45deg
);
}

.admin-dshb-score-settings-body-col,
.admin-dshb-badges-settings-body-col{
    display:flex;
    flex-direction: column;
    gap:20px;
    width: 50%;
}
.admin-dshb-lvl-settings-body-col{
    display:flex;
    gap:20px;
    width: 100%;
}

.admin-dshb-score-settings-body-content,
.admin-dshb-badges-settings-body-content,
.admin-dshb-lvl-settings-body-content{
    display: flex;
    justify-content: space-between;
}

.admin-dshb-score-settings-elem-right-side{
    display: flex;
    align-items: center;
    gap:20px;
    width: 60%;
}

.admin-dshb-badges-settings-elem-right-side{
    display: flex;
    align-items: center;
    gap:20px;
    width: 100%;
}
.admin-dshb-lvl-settings-elem-right-side{
    display: flex;
    align-items: center;
    gap:20px;
    width: auto !important;
}
.admin-dshb-score-settings-header,
.admin-dshb-badges-settings-header,
.admin-dshb-lvl-settings-header{
    font-weight: 700;
}

.admin-dshb-score-settings-title,
.admin-dshb-badges-settings-title,
.admin-dshb-lvl-settings-title{
    font-size: 20px;
}

.admin-dshb-score-settings,
.admin-dshb-badges-settings,
.admin-dshb-lvl-settings{
    background: white;
    padding:30px;
    border-radius: 10px;
}

.admin-dshb-score-settings-content,
.admin-dshb-badges-settings-content,
.admin-dshb-lvl-settings-content{
    display:flex;
    flex-direction: column;
    gap:30px;
}


@media screen and (max-width: 850px) {
    .admin-dshb-score-settings-body-content{
        flex-direction: column;
        gap:20px;
    }
    .admin-dshb-score-settings-body-col{width: 100%;}
    .admin-dshb-score-settings-elem-right-side{
        width: 80%;
    }
    .admin-dshb-badges-settings-body-col{
        width: 100%;
    }
}
@media screen and (max-width: 1100px) {
    .admin-dshb-lvl-settings-body-col{
        flex-direction: column;
        align-items: flex-start;
    }
}
@media screen and (max-width: 800px) {
    .admin-dshb-score-settings-header-btns,
    .admin-dshb-score-settings-header,
    .admin-dshb-badges-settings-header,
    .admin-dshb-lvl-settings-header{
        flex-direction: column;
    }
    .admin-dshb-score-settings-header-btns{
        gap:10px;
    }
    .admin-dshb-score-settings-header,
    .admin-dshb-badges-settings-header,
    .admin-dshb-lvl-settings-header{
        align-items: flex-start;
        gap:10px;
    }
    .admin-dshb-score-settings-elem-left-side{
        width: 150px;
    }
    .empty-gamif-sys-courses-body-art-content{
        width: 80% !important;
        height:80% !important;
    }
    .gamif-sys-courses {
        padding: 20px 20px 70px 20px !important;
    }
    .empty-gamif-sys-courses-body-desc{
        font-size: 16px !important;
    }
    .empty-gamif-sys-courses-header{
        margin-bottom: 20px;
    }
    .empty-gamif-sys-courses-header{
        font-size: 17px !important;
    }
}
.admin-dshb-lvl-settings-elem{
    width: 100%;
    justify-content: space-between;
}
@media screen and (max-width: 500px){
    .admin-dshb-lvl-settings-elem,
    .admin-dshb-score-settings-elem{
        flex-direction: column;
        align-items: flex-start;
    }
}
.admin-dshb-lvl-settings-elem{
    width: auto;
}
.empty-gamif-sys-courses-body-art-content{
    width: 50%;
    height:50%;
    
}
.empty-gamif-sys-courses-body-art,
.empty-gamif-sys-courses-body{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.empty-gamif-sys-courses-body-art-content img{
    width: 100%;
    height:100%;
}
.gamif-sys-courses{
    background: white;
    padding:40px 40px 70px 40px;
    border-radius: 10px;
}
.empty-gamif-sys-courses-body{
    gap:30px;
}
.empty-gamif-sys-courses-body-desc{
    font-size: 22px;
    font-weight: 700;
}
.empty-gamif-sys-courses-header{
    display: flex;
    flex-direction: column;
    gap:10px;
    font-weight: 700;
    font-size: 20px;
}

.empty-gamif-sys-courses-header-btn span{
    font-weight: 500 !important;
    font-size: 16px;
}
.gamif-sys-courses{
    width: 100% !important;
}

.certif-aesthetic-choice-body{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.certif-choice-elem{
    width: 40%;
    height:40%;
}
.certif-choice-elem img{
    width: 100%;
    height:100%;
}
.certif-aesthetic-choice-body button{
    display: flex;
    align-items: center;
    color:white;
    padding:10px 20px;
    background:#5A5A5A;
    border: none;
    gap:10px;
    border-radius: 10px;
    font-weight: 700;
}
.certif-choice-content{
    display: flex;
    justify-content: center;
    gap:100px;
}
.certif-aesthetic-choice-body{
    height:600px;
    display: flex;
    justify-content: center;
    gap:50px;
    flex-direction: column;
}
.certif-aesthetic-choice-content{
    background: white;
    padding:30px;
    border-radius: 10px;
}
.certif-aesthetic-choice-header{
    font-weight: 600;
    font-size: 20px;
}
@media screen and (max-width: 900px){
    .certif-choice-content{
        flex-direction: column !important;
        gap:50px !important;
        align-items: center !important;
        padding:20px;
    }
    .certif-choice-elem {
        width: 80%;
        height: 80%;
    }
    .certif-aesthetic-choice-body{
        height:auto !important;}
}
.admin-payment-section-header-content{
    display: flex;
    align-items: center;
    gap:50px;
    font-size: 18px;
}
.payment-method-img{
    width: 200px;
}
.payment-method-img img{
    width: 100%;
}
.payment-art{
    width:400px;
}
.payment-art img{
    width:100%;
}
.admin-payment-section-body-content{
    display:flex;
    align-items: center;
    gap:25%;
}
.admin-payment-section{
    width: 100%;
    padding:20px;
    background: white;
    border-radius: 10px;
}
.admin-payment-section-body-right-sect button{
    background: #EA0029;
    color:white;
    border: none;
    padding:10px 20px;
    border-radius: 10px;
    width: auto !important;
    font-weight: 700;
}
.admin-payment-section-body-right-sect{
    display: flex;
    flex-direction: column;
    gap:50px;
}
.admin-payment-section-body-left-sect{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap:50px;
}
.admin-payment-section-body-left-sect span{
    font-size: 18px;
    font-weight: 700;
}
.admin-payment-section{
    display: flex;
    flex-direction: column;
    gap:50px;
}
.admin-payment-section{
    height:650px;
}
.admin-payment-section-header-content{
    font-weight: 700;
}
@media screen and (max-width: 900px){
    .admin-payment-section-body-content{
        flex-direction: column-reverse;
        gap:50px;
    }
    .admin-payment-section{
        height:auto !important;
    }
    .payment-art{
        width: 80%;
    }
    .admin-payment-section-body-right-sect{
        align-items: center;
    }
}

@media screen and (max-width: 650px){
    .admin-view-dashboard .course-general-info,
    .admin-view-dashboard .course-description-container{
        width: 100% !important;
    }
}

@media screen and (min-width: 1300px){
    .admin-view-dashboard .course-catalogue-content,
    .admin-view-dashboard .course-catalogue-container{
        width: auto !important;
    }
    .admin-view-dashboard .course-catalogue-container{
        margin-top: 20px;
    }
}





/*Custom Checkbox*/
/* The container */
.admin-view-dshbd-main-sect .user-personal-info-form-container .checkbox-container{
    display: flex;
    width: 100%;
    gap:10px;
}
.admin-view-dshbd-main-sect .user-personal-info-form-container .stats-graph-container label{
    width: 20px;
    margin-top: 3px !important;
}
.admin-view-dshbd-main-sect .user-personal-info-form-container .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .admin-view-dshbd-main-sect .user-personal-info-form-container .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  
  /* When the checkbox is checked, add a blue background */
  .admin-view-dshbd-main-sect .user-personal-info-form-container .container input:checked ~ .checkmark {
    background-color: black;
  }
  
  .admin-view-dshbd-main-sect .user-personal-info-form-container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px black solid;
    background: none;
    border-radius: 50% !important;
}


  /* Show the checkmark when checked */
  .admin-view-dshbd-main-sect .user-personal-info-form-container .container input:checked ~ .checkmark:after {
    display: none !important;
  }



/******/

.admin-dshb-courses-settings .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }


.admin-dshb-courses-settings  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  

  
.admin-dshb-courses-settings  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background:none;
  }
  

  
.admin-dshb-courses-settings  .container:hover input ~ .checkmark {
    background-color: none;
  }
  

  
.admin-dshb-courses-settings  .container input:checked ~ .checkmark {
    background:none;
  }
  

  
.admin-dshb-courses-settings  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  

  
.admin-dshb-courses-settings  .container input:checked ~ .checkmark:after {
    display: block;
  }


