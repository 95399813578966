.dashboard-header, .dashboard-body, .sidebar-body-container{
    background: #EDEDED;
}

.side-menu-container{
    display: none !important;
}

.header-search-box-content{
    display: flex;
    align-items: center;
    gap:20px;
    padding:5px 10px;
    background: white;
    border-radius: 12px;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    width: 50vw;
}


.header-search-box-content input{
    border: none;
    background: none;
    font-weight: 700;
    font-size: 12px;
}

.notification-icon-container, .messages-icon-container{
    position: relative;
}

.notification-icon-container svg, .messages-icon-container svg{
    width: 15px;
}

.red-dot{
    position: absolute;
   width: 8px;
   height:8px; 
   background: #EA0029;
   border-radius: 50%;
}

.dashboard-header .hs-navbar--left-side{
display: flex;
flex-direction: row-reverse;
align-items: center;
gap:15px;
}
.dashboard-header .hs-navbar--right-side {
    display: flex;
    align-items: center;
    gap:10px;
    }
/*
.dashboard-body .bs-main-content{
padding:50px 0px 100px 0px;
}*/

.dashboard-content{
    padding:20px;
}
.dashboard-column-content{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.dashboard-column-search-bar-content{
    background:white;
    padding:10px 15px;
    border-radius: 12px;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
}
.dashboard-completed-courses{
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
}

/*CHANGE INPUT PLACE HOLDER COLOR*/
::placeholder {
    color:#DEE0E6;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:#DEE0E6;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color:#DEE0E6;
  }

.dashboard-column-search-bar-content-right-side{
    display: flex;
    align-items: center;
    gap:10px;
    width: 100%;
}
.dashboard-column-search-bar-content-right-side .search-input-container{
    width: 100%;
}
.dashboard-column-search-bar-content-right-side input{
    border: none;
    font-weight: 700;
    width: 100%;
}
.dashboard-column-search-bar-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:10px;
}
.profits-stat-bar-container, .sells-stat-bar-container, .views-stat-bar-container{
    display: flex;
    gap:10px;
    align-items: center;
}
.profits-stat-bar-container .stat-bar{
    background: #EA0029;
    height:3px;
    width: 75%;
    border-radius: 10px;
}
.profits-stat-bar-container .perc-in-numbers span{
    color:#EA0029;
    font-weight: 700;
}

.sells-stat-bar-container .stat-bar{
    background: #00732F;
    height:3px;
    width: 82%;
    border-radius: 10px;
}
.sells-stat-bar-container .perc-in-numbers span{
    color:#00732F;
    font-weight: 700;
}

.views-stat-bar-container .stat-bar{
    background: #5A5A5A;
    height:3px;
    width: 48%;
    border-radius: 10px;
}
.views-stat-bar-container .perc-in-numbers span{
    color:#5A5A5A;
    font-weight: 700;
}
.dashboard-completed-courses{
    background: white;
    border-radius: 10px;
}
.dashboard-completed-courses-content{
    padding:20px;
    display: flex;
    flex-direction: column;
    gap:15px;
}
.dashboard-completed-courses-header span{
    font-weight: 700;
}

.key-color{
    width: 13px;
    height:13px;
    border-radius: 50%;
}
.dashboard-completed-courses-stats-legend-content span{
    font-weight: 600;
    font-size: 14px;
}
.dashboard-completed-courses-stats-legend-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.profits .key-color{
    background-color: #EA0029;
}
.profits span{
color:#EA0029;
}
.sells .key-color{
    background-color: #00732F;
}
.sells span{
color:#00732F;
}
.views .key-color{
    background-color: #5A5A5A;
}
.views span{
color:#5A5A5A;
}

.profits, .sells, .views{
    display:flex;
    align-items: center;
    gap:10px;
}

.score-label span, .actual--score span, .badges-label span, .badges-number span{
    color: black;
    font-weight: 600;
}

.score--badges-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.score-container, .badges-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding:10px;
    gap:10px;
    width: 40%;
    border-radius: 12px;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
}
.badges-number span, .actual--score span{
    color:#EA0029;
}


/*Calender*/
.calender-body-section-elem{
    background-color: white;
    width: 34px;
    height: 34px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: -4px 7px 15px -7px rgba(0,0,0,0.14);
-moz-box-shadow: -4px 7px 15px -7px rgba(0,0,0,0.14);
box-shadow: -4px 7px 15px -7px rgba(0,0,0,0.14);
}
.calender-body-section-row{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}
.calender-content{
    background: white;
    padding:15px 20px;
    border-radius: 12px;
}
.calender-body-section-content{
    display: flex;
    flex-direction: column;
    gap:10px;
}
.calender-header-section-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:2px 10px;
    background: #EA0029;
    border-radius: 30px;
}
.calender-header-section-content span{
    color:white;
    font-size: 14px;
    font-weight: 600;
    padding:0px 5px;
}
.calender-content{
    display: flex;
    flex-direction: column;
    gap:15px;
}
.lgray--elem{
    background:#EDEDED;
}
.green--elem {
    background:#00732F;
}
.green--elem span, .gray--elem span, .red--elem span{
    color:white;
}
.gray--elem{background: #5A5A5A;}
.red--elem{background:#EA0029;}

.calender-body-section-content span{
    font-weight: 600;
}

.stat-pie-chart-content, .stat-pie-chart{
    position: relative;
    width:100%;
    height:300px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.stat-pie-chart{
    background: tomato;
    border-radius: 12px;
}
.stat-pie-chart-legend-content{
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap:15px;
}
.completed-courses .key-color{
    background: black;
}
.courses-not-started .key-color{
    background:#EA0029;
}
.courses-not-started span{
    color:#EA0029;
}
.courses-in-progress .key-color{
    background: #00732F;
}
.courses-in-progress span{
    color:#00732F;
}
.completed-courses, .courses-not-started, .courses-in-progress{
    display: flex;
    align-items: center;
    gap:20px;
}
.courses-progress-stats-content, .courses-progress-stats-content{
    display: flex;
    flex-wrap: wrap;
    gap:25px;
    align-items: center;
    justify-content: center;
}
.courses-progress-stats-elem, .courses-progress-stats-elem{
    display: flex;
    flex-direction: column;
    gap:15px;
    width: 230px;
}
.dashboard-stats-content, .courses-stats-content{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.dashboard-content{
    display: flex;
    flex-direction: column;
    gap:30px;
}

.courses-stats, .courses-progress-stats, .courses-progress-stats{
    display: flex;
    flex-direction: column;
    gap:15px;
    background: white;
    border-radius: 12px;
    padding:20px 20px 20px 5px;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
}
.courses-progress-stats, .courses-progress-stats{
padding:30px 20px;
}

/*perc-ring Css*/
.courses-progress-stats-elem svg, .courses-progress-stats-elem svg {
    /*transform: rotate(-90deg);*/
    width: 100%;
    height: 100%;
    fill: none;
  }
  .courses-progress-stats-elem svg circle, .courses-progress-stats-elem svg circle {
    strokeWidth: 3;
    stroke: rgb(216, 216, 216);
  }
  .courses-progress-stats-elem svg .bar, .courses-progress-stats-elem svg .bar  {
    strokeWidth: 7;
    stroke-linecap: round;
  }
  .-gray-stats--bar svg .bar{
    stroke: #5A5A5A !important;
  }
  .-green-stats--bar svg .bar{
    stroke: #00732F !important;
  }
  .-red-stats--bar svg .bar{
    stroke: #EA0029 !important;
  }
  .-black-stats--bar svg .bar{
    stroke: black !important;
  }
.courses-progress-stat-perc-ring-content, .courses-progress-stat-perc-ring-content{
    width: 188px;
    height:188px;
}
.courses-progress-stat-perc-ring, .courses-progress-stat-perc-ring{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.perc-in-number{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.perc-in-number span{
    font-weight: 700;
    font-size: 40px;
}
.courses-progress-stats-elem, .courses-progress-stats-elem{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.stats-related-course span{
    font-weight: 700;
    font-size: 20px;
}
.courses-progress-stats-header span, .courses-stats-header span, .courses-progress-stats-header span{
    font-weight: 700;
    font-size:20px;
}
.course--progress-bar{
    background:#DEE0E6;
    width:100%;
    height:15px;
    border-radius: 14px;
}
.actual-progress{
    background:#5A5A5A;
    height:15px;
    width:50%;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: -7px 2px 12px 0px rgba(0,0,0,0.17);
    -moz-box-shadow: -7px 2px 12px 0px rgba(0,0,0,0.17);
    box-shadow: -7px 2px 12px 0px rgba(0,0,0,0.17);
}
.actual-progress span{
    font-size: 10px;
    color:white;
    font-weight: 600;
    
}
.dashboard-body .carousel-elem-box-text{
    display: flex;
    flex-direction: column;
    gap:10px;
    padding-top:5px;
}
.dashboard-body .course--progress-bar-container{
    width: 90%;
}
.dashboard-body .carousel-elem-box{
    width:225px;
    height:230px !important;
}
.dashboard-body .carousel-elem-box a{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:15px;
} 
.dashboard-body .carousel-elem-box-img{
width: 120px;
height:120px;
}
.dashboard-body .carousel-elem-box-img img{
    width: 100%;
    height: 100%;
}

.dashboard-body .bsSliderInter{
    background: white;
    border-radius: 12px;
}
.dashboard-body .bsSliderInter{
    padding:15px;
}



@media screen and (min-width: 500px){
    .dashboard-body .carousel-elem-box-img{
        width: 100%;
    }
    .dashboard-body .carousel-elem-box-img img{
        width: 130px;
        height:130px;
        margin-bottom: 20px;
        }
    .dashboard-body .course--progress-bar-container{
        width: 100%;
    }
    .dashboard-body .carousel-elem-box-text{
        width: 150px;
    }
}

@media screen and (min-width: 600px){
    .courses-stats-content .carousel-elem-box-text{
        width:180px;
    }
    .dashboard-body .courses-stats-content{
        flex-direction: row !important;
    }
    .stat-pie-chart{
        width: 50%;
        height:200px;
    }
    .courses-stats-content{
        align-items: center;
    }
    .dashboard-header .header-search-box-content svg{
        display: block;
    }
    .dashboard-header .header-search-box-content svg{
        width: 15px;
    }
    .dashboard-header .hs-navbar--right-side{
        gap:20px;
    }
}

@media screen and (min-width: 620px){
    .dashboard-body .carousel-elem-box{
        width: 228px !important;
        height: 235px !important;
    }
}

@media screen and (min-width: 800px){
    .dashboard-body .carousel-elem-box-img{
        height:150px !important;
    }
    .notification-icon-container svg, .messages-icon-container svg{
        width: 18px;
    }
    .dashboard-header .header-search-box-content svg{
        width: 18px;
    }
    .header-search-box-content input{
        font-size: 15px;
    }
}

@media screen and (min-width: 900px){
    .dashboard-content{
        flex-direction: row-reverse;
    }
    .sidebar-body-container{
        display: flex;
    }
    .body-section{
        width: 100%;
    }
    .dashboard-column{
        width: 50vw;
    }
    .dashboard-column-content, .dashboard-column-elem, .dashboard-stats-container{
        width: 100% !important;
    }
    .dashboard-body .carousel-elem-box-img img{
        width: 110px;
        height:110px;
    }
    .dashboard-body .carousel-elem-box-img{
        height:110px !important;
    }
    .dashboard-body .carousel-elem-box{
        height:200px !important;
        width:210px !important;
    }
    .dashboard-header .hs-navbar--right-side{
        gap:40px;
    }
    .dashboard-header .header-search-box-content{
        padding:8px;
    }
    .stats-related-course span{
        font-size: 12px;
    }
}

@media screen and (min-width: 1000px){
    .carousel-elem-box-text span{
        font-size:14px !important;
    }
    .dashboard-body .carousel-elem-box {
        width: 228px !important;
        height: 245px !important;
    }
    .dashboard-column:nth-child(1){
        width: 35%;
    }
    .dashboard-column:nth-child(2){
        width: 65%;
    }
    .courses-progress-stat-perc-ring-content, .courses-progress-stat-perc-ring-content{
        width:120px;
        height:120px;
    }
    .stats-related-course span{
        font-size: 14px;
    }
    .courses-progress-stats-elem, .courses-progress-stats-elem{
        width:180px !important;
    }
    .courses-progress-stats-content, .courses-progress-stats-content{
        flex-wrap: nowrap;
    }
}
@media screen and (max-width: 1300px){
.sidebar-body-container .sidenavbar{
display: none !important;
}
.user-main-score{
    width:89px !important;
}
}
.sidebar-container{
    position: relative;
}
.sidebar-container, .sidebar-content{
    height: 100%;
}
.dashboard-body .bsSliderInter{margin-top: 0px;}


.mob-sidenav-ver .modal-dialog{
    width: 60% !important;
}


@media screen and (min-width: 1300px){
    .sidebar-body-container{
        gap:10px;
    }
    .sidebar-body-container{
        padding:50px 0px 100px 0px; 
    }
    .sidebar-container{
        display: block !important;
    }
    .score-container, .badges-container{
        padding:10px 15px;
    }
    .courses-progress-stats, .courses-progress-stats{
        padding:20px 10px;
    }
    .dashboard-content{ 
        width: 100%;
        padding:0px 0px 0px 60px;
        display:flex;
    }
    .dashboard-column:nth-child(1){
        width: 28%;
    }
    .dashboard-content{
        gap:10px;
    }
    
    .dashboard-column:nth-child(2){
        width: calc( 100% - 332px );
    }
    .dashboard-column:nth-child(3), .sidenavbar{
        width: 18%;
    }
    .dashboard-column .dashboard-column-content{
        height:100% !important;
    }
    .dashboard-carousel-cont{height:100%;}
    .dashboard-body .bsSliderInter{
        height:100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .dashboard-stats-content{
        flex-direction: row-reverse;
    }
    .courses-progress-stats-elem, .courses-progress-stats-elem{
        width: 120px !important;
    }
    .courses-progress-stats-content, .courses-progress-stats-content{
        gap:15px;
        align-items: flex-start;
    }
    .courses-progress-stat-perc-ring-content, .courses-progress-stat-perc-ring-content{
        width: 80px;
        height:80px;
    }
    .perc-in-number span{
        font-size:25px;
    }
    .dashboard-stats-content .courses-stats, .dashboard-stats-content .courses-progress-stats, .courses-progress-stats{
        width: 50%;
    }
    
    .dashboard-container{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .stats-related-course{
        display: flex;
        justify-content: center;
    }
    .stats-related-course span{
        text-align: center;
        font-size: 12px;
    }
    .courses-progress-stats, .courses-progress-stats{
        gap:30px;
    }
    .courses-stats{
        gap:30px;
    }
    .stat-pie-chart-legend-content span{
        font-size: 13px;
    }
    .completed-courses, .courses-not-started, .courses-in-progress, .courses-stats-content{
        gap:10px;
    }
    .stat-pie-chart-content{
        height:auto;
    }
    .stat-pie-chart{
        height:150px;
    }
    .dashboard-stats-content{
        gap:10px;
    }
    .dashboard-content{
        gap:10px;
    }
    .dashboard-body .carousel-elem-box-img{
        height: 100px !important;
    }
    .dashboard-body  .carousel-elem-box .carousel-elem-box-img img{
        width: 100px !important;
        height: 100px !important;
    }
    .dashboard-body .carousel-elem-box{
        width: 150px !important;
        height: 200px !important;
    }
    .dashboard-body .carousel-elem-box-text span{
        font-size: 12px !important;
    }
    .dashboard-body .course--progress-bar-container{
        display: flex;
        justify-content: center;
    }
    .course--progress-bar{width: 90%;}
    .dashboard-column:nth-child(2) .dashboard-column-content{
        gap:10px;
    }
    .dashboard-body .carousel-item .container{
        gap:25px;
    }
    .dashboard-body .carousel-header span{
        font-size:20px;
    }
    
}
.carousel-header{
    padding:5px 10px;
}

.carousel-header span{
    font-weight: 700; 
}
.courses-progress-stats-header, .courses-progress-stats-header{
    padding-right:10px;
}

.dashboard-column:nth-child(3), .sidenavbar{
    display:flex;
    flex-direction: column;
    gap:25px;
    /*position: absolute;
    right: 0;*/
}

.side-menu-btn, .side-score-indic{
    width:100%;
    height:120px;
    background:#707070;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}
.side-menu-btn-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:5px;
    width: 100%;
}
.left-arrow{
    width: 18px;
}
.side-menu-btn{
    display: flex;
    align-items: center;
}

/*Side bar Nav*/
.side-menu-content{
    background: #5A5A5A;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 15px;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    height:100%;
    display: flex;
    flex-direction: column;
    gap:20px;
}
.side-menu-user-content span{
    font-size: 14px;
    color:white;
    font-weight: 700;
}
.side-menu-user-content{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.side-menu-user-content .user-side-bar-icon{
    width: 30px;
}
.side-menu-user-content .down-arrow{
    width: 20px;
}
.nav-icon-container{
    width: 20px;
}
.nav-icon-container svg{
    width: 100%;
}
.side-menu-nav-elem-content span{
    font-size: 14px;
    color:white;
    font-weight: 700;
}
.side-menu-nav-content{
    display: flex;
    flex-direction: column;
    gap:8px;
}
.side-menu-nav-elem-content{
    width: 80%;
display: flex;
align-items: center;
padding:10px 20px 10px 0px;
gap:15px;
}
.side-menu-user-container{
    padding:50px 0px 0px 0px;
}
.side-menu-nav-container{
    padding:20px 0px;
}

.active-nav .side-menu-nav-elem-content{
    background: white;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}
.active-nav span{
    color:#EA0029;
}
.active-nav svg path{
    fill:#EA0029;
}
.active-nav svg g path{
    stroke:#EA0029;
}

.side-menu-nav-elem-content{
cursor: pointer;
}
.side-score-indic-content{
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:15px;
}
.side-score-indic span{
    color:white;
    font-weight: 700;
}


@media screen and (min-width: 1330px){
    .dashboard-body .dashboard-column:nth-child(1){
            width: 332px ;
    }
    .dashboard-body .carousel-elem-box {
        width: 180px !important;
        height: 220px !important;
    }
    .dashboard-body #bsSliderInter{
        gap:0px !important;
    }
    .dashboard-body #bsSliderInter a{
        width: 4%;
    }
    .dashboard-body .carousel-elem-box .carousel-elem-box-img img{
        width: 120px !important;
        height: 120px !important;
    }
    .dashboard-body .carousel-elem-box-text span {
        font-size: 14px !important;
    }
    .dashboard-body .carousel-elem-box{gap:15px;}
    .course--progress-bar{
        width: 100%;
    }

}

@media screen and (min-width: 1440px){
    .dashboard-body .bsSliderInter{
        gap:0px;
    }
    .dashboard-body .dashboard-column:nth-child(2){
        width: 75% !important;
    }
    .dashboard-body .dashboard-column:nth-child(3), .sidenavbar{
        width:25%;
    }
    .dashboard-body .carousel-elem-box{
        width: 180px !important;
        height: 220px !important;
    }
    .dashboard-body .carousel-elem-box-text span{
        font-size: 14px !important;
    }
    .dashboard-body .bsSliderInter{
        padding:18px;
    }
    .side-menu-nav-elem-content span{
        font-size: 18px !important;
    }
    .side-menu-user-content span{
        font-size:17px !important;
    }
    .user-side-bar-icon{
        width: 45px !important;
    }
    .side-menu-nav-elem-content{
        padding: 15px 25px 15px 0px;
    }
    .nav-icon-container{
        width: 24px;
    }
    .courses-progress-stat-perc-ring-content, .courses-progress-stat-perc-ring-content{
        width: 90px;
        height:90px;
    }
    .dashboard-body .carousel-elem-box {
        width: 200px !important;
        height: 240px !important;
    }
}

@media screen and (min-width: 1600px){
    .dashboard-body .bsSliderInter .container{
        justify-content: center;
        gap:30px;
    }
    .side-menu-nav-container{padding:0px;}
    .dashboard-body .carousel-elem-box{
        width: 210px !important;
        height: 240px !important;
    }
    .dashboard-content{
        padding-left:100px;
    }
    .dashboard-body .dashboard-column:nth-child(1) {
        width: 30%;
    }
    .dashboard-body .calender-content{
        padding-left:10%;
        padding-right:10%;
    }
    .hs-navbar{
        padding:60px 100px;
    }
    .dashboard-completed-courses-header span{
        font-size:17px !important;
    }
    .courses-progress-stat-perc-ring-content, .courses-progress-stat-perc-ring-content{
        width:100px;
        height:100px;
    }
    .perc-in-number span{
        font-size: 28px;
    }
    .stats-related-course span {
        font-size: 14px;
    }
    .completed-courses span, .courses-not-started span, .courses-in-progress span{
        font-size: 14px;
    }
    .side-menu-nav-elem-content span{
        font-size:23px !important;
    }
    .nav-icon-container{
        width: 30px;
    }
    .side-menu-nav-elem-content{
        padding: 15px 30px 15px 0px;
    }
    .user-side-bar-icon{
        width: 60px !important;
    }
    .down-arrow{
        width: 30px !important;
    }
    .side-menu-user-content span{
        font-size: 18px !important;
    }
    .dashboard-body .bsSliderInter .carousel-inner{padding:18px 0px;}
    .dashboard-carousel-cont {
        height: auto;
    }
    .side-menu-nav-elem-content span{
        font-size:20px !important;
    }
    .dashboard-column:nth-child(1) .dashboard-column-content{height:100%;
        justify-content: space-between;
    }
}

@media screen and (min-width: 1750px){
    .hs-navbar{
        padding:60px 140px;
    }
    .dashboard-content{
        padding-left:140px;
    }
    .dashboard-completed-courses-header span{
        font-size:20px !important;
    }
}
@media screen and (min-width: 1920px){
    .hs-navbar{
        padding:60px 160px;
    }
    .dashboard-content{
        padding-left:160px;
    }

}


.gray-prog-bg{
    background: #707070;
}
.red-prog-bg{
    background: #EA0029;
}
.green-prog-bg{
    background: #00732F;
}