::-webkit-scrollbar {
    width: 8px;
    border-radius: 12px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background:#DEE0E6;
    border-radius: 12px; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: black; 
    border-radius: 12px; 
  }
  


.achievement-badges-content{
    display: flex;
    flex-wrap: wrap;
    gap:15px;
}

.earned-badge svg path{
    fill:#b37e10;
}
.badge-points{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.badge-points span{
    font-size: 13px;
    font-weight: 700;
}
.achievement-name span{
    font-size: 18px;
    font-weight: 700;
    color:#FD6150;
}
.user-badges-achievements-content{
    padding: 20px;
}
.user-badges-achievements-row-content{
    display: flex;
    flex-direction: column;
    gap:25px;
}
.achievement-badge{
    display: flex;
    flex-direction: column;
    gap:8px;
}
.user-badges-achievements-content{
    display: flex;
    flex-direction: column;
    gap:30px;
}

.user-badges-achievements-section{
    background: white;
    border-radius: 12px;
}
.score-placements-row-elem{
    display: flex;
    align-items: center;
    gap:10px;
}
.score-placements-row-elem svg{
    width: 30px;
}
.score-placements-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.user-score{
    background: black;
    border-radius: 15px;
    padding:0px 10px;
}
.score-placements-body span{
    font-weight: 700;
}
.user-score span{
    color:white;
}
/*
.score-placements{
    height:460px;
}*/

.score-placements{
    height:300px;
}
.score-placements-container{height:100%;}
.score-placements-content{
    background: white;
    border-radius: 12px;
    height:100%;
    padding:20px;
}
.score-placements-body-content{
    overflow-x: auto;
    padding-left: 20px;
    height:235px;
}
.vert-scroll-container{
    display: flex;
    flex-direction: column;
    gap:10px;
}
.actual-user .score-placements-row-elem svg path{
    fill:#EA0029;
}
.actual-user .user-name span{
    color:#EA0029;
}
.actual-user .user-score{
    background: #EA0029;
}
.score-placements-header select, .score-placements-header span{
    border: none;
    font-weight: 700;
    font-size: 18px;
}
.score-placements-header select{
    width: 62%;
}
.score-placements-header{
    display: flex;
    margin-bottom: 15px;
}

@media screen and (min-width: 1300px){
    .score-placements-header{
        margin-bottom: 10px;
    }
    .achievement-badge svg, .achievement-badge{
        width: 60px;
    }
    .user-badges-achievements-content{
        gap:10px;
    }
    .user-badges-achievements-row-content{gap:10px;}
    .user-badges-achievements-dashboard .-pcr-co, .user-badges-achievements-dashboard .-pcr-co .user-progress-cpr-others-content, .user-badges-achievements-dashboard .-pcr-co .user-progress-cpr-others{
        height:100%;
    }
    .achievement-badges-content{
        gap:25px;
    }
    .score-placements-content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .user-badges-achievements-dashboard .-p-progr-info{
        height:70%;
    }
    .user-badges-achievements-dashboard  .dashboard-user-progress-info{
        height:100%;
    }
    .user-badges-achievements-dashboard .dashboard-user-progress-info{
        display: flex;
        align-items: center;
    }
    .user-badges-achievements-dashboard .dashboard-user-progress-info-content{
        width: 100%;
        height:80%;
        justify-content: space-between;
    }
}
@media screen and (min-width: 1440px){
    .score-placements-header select{
        width: 60%;
    }
}
@media screen and (min-width: 1600px){
    .user-badges-achievements-row-content{
        flex-direction: row;
        gap:25px;
    }
    .achievement-name{
        padding-top: 10px;
    }
    .user-badges-achievements-dashboard .-p-progr-info{
        height:auto !important;
    }
    .score-placements{
        height:250px;
    }
    .score-placements-body-content{
        height:160px;
    }
    .user-badges-achievements-dashboard .dashboard-user-progress-info-content{
        height:auto;
    }

    .user-badges-achievements-content{
        padding:30px 20px;
    }
}

@media screen and (min-width: 1700px){
    .achievement-name{
        width: 140px;
    }
    .achievement-name span{
        font-size: 20px;
    }
    .badge-points span{
        font-size: 14px;
    }
    .user-badges-achievements-dashboard .-p-progr-info{
        height:55% !important;
    }
    .achievement-badges-content{
        gap:30px;
    }


}