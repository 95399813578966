.bs-main-content-header-sm-tr{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:10px;
    padding:30px;
}
.bs-main-content-header-title-sm-tr span{
    font-size: 24px;
    font-weight: 700;
    color:#00732F;
}
.bs-main-content-header-desc-sm-tr span{
    font-size: 18px;
    font-weight: 500;
}
.cards-container{
    padding:30px 0px;
}
.cards-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:20px;
    padding:20px;
}
.cardes-wrapper-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap:20px;
}
.card-wrapper-elem-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background:white;
    width:190px;
    height:200px;
    gap:15px;
    padding-top: 5px;
    border-radius: 12px;
    -webkit-box-shadow: 0px 6px 16px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 6px 16px -6px rgba(0,0,0,0.75);
    box-shadow: 0px 6px 16px -6px rgba(0,0,0,0.75);
}
/**/
@media screen and (min-width: 440px){
    .cardes-wrapper-content,
    .photos-ressources-body .cardes-wrapper-content{
        width: 410px;
        gap:20px;
        justify-content: flex-start;
    }
    
}

@media screen and (min-width: 700px){
    .cardes-wrapper-content,
    .photos-ressources-body .cardes-wrapper-content{
        width: 650px;
        gap:40px;
        justify-content: flex-start;
    }
}

@media screen and (min-width: 760px){
    .card-wrapper-elem-content,
    .photos-ressources-body .cards-wrapper-elem button{
        width:190px !important;
        height:220px !important;
    }

}
@media screen and (min-width: 900px){
    .card-wrapper-elem-content{
        width:190px !important;
        height:220px !important;
    }
    .zoom-training-body .cardes-wrapper-content,
    .photos-ressources-body .cardes-wrapper-content{
        width: 650px !important;
        gap:40px !important;
        justify-content: center;
    }
}
@media screen and (min-width: 1000px){
    .card-wrapper-elem-content,
    .photos-ressources-body .cards-wrapper-elem button{
        width:190px !important;
        height:220px !important;
    }
    .zoom-training-body .cardes-wrapper-content,
    .photos-ressources-body .cardes-wrapper-content{
        width: 850px !important;
        gap:30px !important;
        justify-content: center;
    }
}
@media screen and (min-width: 1300px){
    .card-wrapper-elem-content,
    .photos-ressources-body .cards-wrapper-elem button{
        width:210px !important;
        height:240px !important;
    }
    .zoom-training-body .cardes-wrapper-content,
    .photos-ressources-body .cardes-wrapper-content{
        width: 1100px !important;
        gap:30px !important;
    }
}

/**/

.cards-wrapper-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.cards-wrapper-img {
    width:100px;
    height:100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cards-wrapper-text{
    width: 80%;
    display: inline-flex;
    height: 40px;
    justify-content: center;
}
.cards-wrapper-text span{
    margin: 0px auto;
    font-size: 12px;
    text-align:center !important;
    font-weight: 700;
}

@media screen and (min-width: 800px){
    .bs-main-content-header-title-sm-tr span{
        font-size: 30px;
        font-weight: 700;
        color:#00732F;
    }
    .bs-main-content-header-desc-sm-tr span{
        font-size: 22px;
        font-weight: 500;
    }
}

@media screen and (min-width: 1200px){
    
    .cardes-wrapper-content{
        gap:40px !important;
    }
    .card-wrapper-elem-content{
        width: 200px;
        height:210px;
    }
    .cards-wrapper-img {
        width:160px;
        height:160px;
    }
    .cards-wrapper-text span{
        margin: 0px auto;
        font-size: 16px;
    }
    .zoom-training-body .cardes-wrapper-content{
        width: 1000px !important;
    }
    .zoom-training-body .card-wrapper-elem-content{
        width: 230px !important;
        height:240px !important;
        
    }
    .zoom-training-body .cardes-wrapper-content{
        gap:26px !important;
        
    }
    .zoom-training-body .card-wrapper-elem-content{
        justify-content: space-between !important;
        padding:15px 0px 25px 0px!important;
    }
    .zoom-training-body .cards-wrapper-img {
        width:145px !important;
        height:145px !important;
    }
    .zoom-training-body .cards-wrapper-text span{
        margin: 0px auto;
        font-size: 15px !important;
    }
    .zoom-training-body .course-card-title span{
        font-size: 18px !important;
    }
    .zoom-training-body .cards-wrapper-text{
        gap:6px;
    }

}


@media screen and (min-width: 1300px){
    .bs-main-content-header-title-sm-tr span{
        font-size: 40px;
        font-weight: 700;
        color:#00732F;
    }
    .bs-main-content-header-desc-sm-tr span{
        font-size: 30px;
        font-weight: 500;
    }
}


@media screen and (min-width: 1440px){
    .cardes-wrapper-content{
        width:1300px;
    }
    .cardes-wrapper-content{
        gap:20px !important;
    }
    .cards-wrapper-img{
        width: 200px !important;
        height:200px !important;
    }
    .cards-wrapper-text span{
        font-size: 20px !important;
    } 
    .zoom-training-body .cardes-wrapper-content{
        width: 1100px !important;
    }
    .zoom-training-body .cardes-wrapper-content{
        gap:40px !important;
    }
}

@media screen and (min-width: 1600px){

    .cardes-wrapper-content{
        gap:40px !important;
    }

}
@media screen and (min-width: 1920px){

    .cardes-wrapper-content{
        gap:50px !important;
    }
}
@media screen and (min-width: 680px){
    .cards-wrapper, .cards-wrapper-row{
        gap:60px;
    }
}
@media screen and (min-width: 760px){
    .card-wrapper-elem-content{
        width: 200px;
        height:210px;
    }
    .cards-wrapper-img {
        width:120px;
        height:120px;
    }
    .cards-wrapper-text span{
        margin: 0px auto;
        font-size: 14px;
    }
}

@media screen and (min-width: 900px){

    .cards-wrapper-img {
        width:120px;
        height:120px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .cards-wrapper-text span{
        margin: 0px auto;
        font-size: 13px;
        text-align:center !important;
        font-weight: 700;
    }
    .card-wrapper-elem-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background:white;
        width:200px;
        height:210px;
        gap:15px;
        padding-top: 5px;
        border-radius: 12px;
        -webkit-box-shadow: 0px 6px 16px -6px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 6px 16px -6px rgba(0,0,0,0.75);
        box-shadow: 0px 6px 16px -6px rgba(0,0,0,0.75);
    }
    .cardes-wrapper-content{
        gap:20px;
    }
}
/*
@media screen and (min-width: 1600px){
    .cardes-wrapper-content{
        gap:40px;
    }
    .card-wrapper-elem-content{
        width: 349px;
        height:356px;
    }
    .cards-wrapper-img {
        width:202px;
        height:202px;
    }
    .cards-wrapper-text span{
        margin: 0px auto;
        font-size: 22px;
    }
}*/


.pagination-content-elem{
    width:35px;
    height:35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    -webkit-box-shadow: 0px 6px 16px -6px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 6px 16px -6px rgba(0,0,0,0.75);
    box-shadow: 0px 6px 16px -6px rgb(0 0 0 / 75%);
}
.pagination-content-elem span{
    font-weight: 700;
    color:black;
}
.last-pagination-number{
    background: #EA0029;
}
.last-pagination-number span{
    color:#FFFFFF;
}
.pagination-dot{
    width:3px;
    height:3px;
    border-radius: 50%;
    background: black;
}
.smart-training-header, .smart-training-body, .direct-training-body, .direct-training-body{background: #EDEDED;}

.pagination-content{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap:10px;
}
.pagination-container{padding:60px 0px;}