.registered-memebers-header, .registered-memebers-body,
.registered-instructors-header, .registered-instructors-body,
.registered-institutions-header, .registered-institutions-body{
    background: #EDEDED;
}

.registered-memebers-body .cards-wrapper-img , .registered-instructors-body .cards-wrapper-img, .registered-institutions-body .cards-wrapper-img{
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.registered-memebers-body .cards-wrapper-img img, .registered-instructors-body .cards-wrapper-img img, .registered-institutions-body .cards-wrapper-img img{
    width: 50%;
    height:50%;
    object-fit: contain;
    border-radius: 0px;
}
.registered-memebers-body .cards-wrapper-img, .registered-instructors-body .cards-wrapper-img, .registered-institutions-body .cards-wrapper-img{
    background: #F8F8F8;
}
.search-input-field-content{
    display: flex;
    align-items: center;
    gap:20px;
}
.search-sort-container{
    width: 100%;
    display: flex;
    justify-content: center;
}
.search-input-field-content input{
    width:100%;
    padding:8px;
    border: none;
    background: none;
    font-weight: 700;
}
.search-input-field-container{
    background: white;
    padding: 0px 20px;
    border-radius: 12px;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
}
.search-sort-content{
    display: flex;
    align-items: center;
    gap:15px;
    width: 90%;
    flex-direction: column;
}

.search-input-field-container{
    width: 100%;
}
/*
.search-input-field-container{
    width: 80%;
}*/

.search-sort-content .dropdown{
    width: 100%;
}

.search-input-field-container svg {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .search-sort-content button{
      width: 100%;
  }
  @media screen and (min-width: 760px) and (max-width: 900px){
    .search-sort-content button{
        font-size: 12px;
    }
  }
  @media screen and (min-width: 760px){
    .search-sort-content{
        flex-direction: row;
    }
    .search-btn-mem{
        width: 30% !important;
    }
    .dropdown{
        width:auto !important;
    }
  }

  @media screen and (min-width: 900px){
    .search-sort-container{
        width: 100%;
    }
    .search-sort-content{
        width:100%;
    }
    .search-sort-container{
        width: 861px;
    }
  }
  @media screen and (min-width: 1200px){
    .search-sort-container{
        width: 920px;
    } 
  }

  @media screen and (min-width: 1100px){
    .registered-memebers-body .cardes-wrapper-content,
    .registered-instructors-body .cardes-wrapper-content,
    .registered-institutions-body .cardes-wrapper-content{
        width: 920px !important;
        justify-content: flex-start;
    }
    .registered-memebers-body .cards-wrapper,
    .registered-instructors-body .cards-wrapper,
    .registered-institutions-body .cards-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .registered-memebers-body .cards-wrapper-text span,
    .registered-instructors-body .cards-wrapper-text span,
    .registered-institutions-body .cards-wrapper-text span{
        font-size: 16px !important;
    }
  }
  @media screen and (min-width: 1440px){
    .registered-memebers-body .cards-wrapper-img,
    .registered-instructors-body .cards-wrapper-img,
    .registered-institutions-body .cards-wrapper-img{
        width: 130px !important;
        height: 130px !important;
    }
    .registered-memebers-body .cards-wrapper-text span,
    .registered-instructors-body .cards-wrapper-text span,
    .registered-institutions-body .cards-wrapper-text span{
        font-size: 16px !important;
    }
  }
/*
  @media screen and (min-width: 1440px){
    .search-sort-container{
        width: 1060px;
    } 
  }
  @media screen and (min-width: 1600px){
    .search-sort-container{
        width: 1120px;
    } 
  }
  @media screen and (min-width: 1920px){
    .search-sort-container{
        width: 1150px;
    } 
  }*/

  .search-sort-content button{
      background: #5A5A5A !important;
      border-radius: 12px;
  }

  .search-sort-content button:hover{
    background: #5A5A5A !important;
}
.search-sort-content button:focus{
    background: #5A5A5A !important;
}
.search-sort-container button{
    font-weight: 700;
}

.cards-wrapper-elem a{
    text-decoration:none;
    color:black;
}
.cards-wrapper-elem a:hover{
    text-decoration:none;
    color:black;
}