.e-lib-header, .e-lib-body{
    background: #EDEDED;
}

.e-lib-body .bs-subsection-elem-cards-content a{
    color:black;
    text-decoration: none;
}
.e-lib-body .bs-subsection-elem-cards-content a:hover{
    color:black;
    text-decoration: none;
}
@media screen and (min-width: 1200px){
    .e-lib-body .bs-cards-content {
        width: 250px;
        height: 250px;
    }
    .e-lib-body .bs-subsection-elem-cards-content {
        gap: 5vw !important;
    }
    .e-lib-body .bs-card-text span{
        font-size: 20px !important;
    }
}