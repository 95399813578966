.direct-training-header, .direct-training-body{
    background: #EDEDED;
}

.direct-training-section-header-title span{
    font-weight: 700;
    font-size: 24px;
    color: #00732F;

}
.direct-training-section-header-desc span{
        font-weight:700;
    }
.direct-training-section-header-desc{
    text-align: center;
}
.direct-training-section-header-title{
    padding-bottom:20px;
}
.direct-training-section-elem{
    padding:20px;
}
.header--subSect{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  gap:10px;
}
.direct-training-section-elem-cards-container{
    display: flex;
    flex-direction: column;
    gap:30px;
}
.direct-training-section-elem-card-content{
    background: #FFFFFF;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:10px;
    padding:20px;
    border-radius: 10px;
}
.direct-training-section-elem-card-header span{
    color: #EA0029;
    font-size: 20px;
    font-weight:700;
}
.consult-fil--btn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-red{
    background: #EA0029;
    color:white !important;
    font-weight: 700;
    padding:10px 30px;
}
.btn-red:focus{
    background: #EA0029;
    color:white !important;
    font-weight: 700;
}

.descriptif-text{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.descriptif-text span{
    text-align: center;
    font-weight: 700;
}
.sperator{
    width: 4px;
    height:4px;
    background: black;
    opacity:0.7;
    border-radius: 50%;
}
.direct-training-section-elem-card{
    background:white;
    border-radius:10px;
}
.courses-section-body-elem{
    background:white;
    padding:20px;
    display: flex;
    flex-direction: column;
    gap:15px;
    border-radius:10px;

}
.course-name-code--cont span{
    font-size: 17px;
}

.course-name-enroll-btn .btn-red{
    padding:5px 10px !important;
}
.course-name-code--cont{
    width: 100%;
    display:flex;
    flex-direction: column;
    gap:10px;
}
.course-name-code--cont span{
    font-weight: 700px !important;
}
.courses-section-body{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.courses-section-content{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.courses-section-header-content{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:10px;
    background: #5A5A5A;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.courses-section-header-content span{
    color:white;
    font-weight:700px !important;
}

@media screen and (min-width: 600px) {
    .direct-training-section-elem{
        padding:20px 50px;
    }
}

@media screen and (min-width: 800px){
    .direct-training-section-elem-cards-container{
        flex-direction: row;
    }
    .direct-training-section-elem-card{
        width: 100%;
    }
}


.courses-section--desk-ver-header-content, .courses-section--desk-ver-body-content-elem{
    color:white;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:10px;
}
.courses-section--desk-ver-header-content span{
    width: auto !important;
}
.course-code-column-name{
    background:#00732F;
    width: 150px;
    padding :10px 0px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.course-name-column-name{
    background:#5A5A5A;
    padding :10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.course-enroll-btn-column-name{
    background:#EA0029;
    width: 150px;
    padding :10px 0px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}




.course-code--des-ver{
    height: auto;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.course-name--des-ver span{
    color:black;
}
.course-name--des-ver{
    background:white;
    padding :10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    
}
.course-enroll-btn--des-ver{
    background:#EA0029;
    width: 150px;
    padding :10px 0px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.course-code--des-ver-content{
    width:40%;
    height:100%;
    background:#00732F;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:10px 0px;
}

.courses-section--desk-ver-content{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.courses-section--desk-ver-body-content{
    display: flex;
    flex-direction: column;
    gap:15px;
}
.courses-section--mob-ver{
    display: block;
}
.courses-section--mob-ver{
    padding-bottom: 60px;
}
.courses-section--mob-ver .courses-section-header-content{
    font-weight: 700;
}

.course-name--des-ver, .course-enroll-btn--des-ver, .course-code--des-ver-content,
.course-code-column-name, .course-name-column-name, .course-enroll-btn-column-name {
    -webkit-box-shadow: 3px 3px 9px 1px rgba(0,0,0,0.22);
    -moz-box-shadow: 3px 3px 9px 1px rgba(0,0,0,0.22);
    box-shadow: 3px 3px 9px 1px rgba(0,0,0,0.22);
}
.courses-section--desk-ver{
    padding:40px 40px 60px 40px;

}
@media screen and (min-width: 1000px) {
    .courses-section--mob-ver{
        display: none;
    }
    .courses-section--desk-ver{
        display: block !important;
    }
    .direct-training-section-elem{
        padding:50px;
    }
}

@media screen and (min-width: 1200px) {
    .training-prgram-body .bs-main-container{
        padding: 20px 5vw;
    }
    .direct-training-section-header-desc{
        width:800px;
    }
    .direct-training-section-header-desc span{
        font-size:18px;
    }
     .direct-training-section-header-title span{
        font-size:45px !important;
    }
}

@media screen and (min-width: 1440px) {
    .direct-training-section-header-title span{
        font-size: 45px;
    }
    .direct-training-section-header-desc span{
        font-size: 23px;
    }
    .direct-training-section-elem-card-header span{
        font-size: 18px;
    }
    .direct-training-section-elem-card-body{
        font-size: 18px;
    }
    .consult-fil--btn button{
        font-size: 20px;
    }
    .descriptif-text{
        font-size: 23px;
    }
    .courses-section--desk-ver-content{
        font-size:23px;
    }
    .course-code-column-name{
        background:#00732F;
        width: 150px;
    
    }
    .course-enroll-btn-column-name{
        background:#EA0029;
        width: 150px;

    }
}

.bold-sp{
    font-weight: 700;
}
.courses-section--desk-ver{
    display: none;
}
.direct-training-section-elem-card span{
    font-weight: 700;
}

.direct-training-card{
    width:250px;
    height:280px;
    border-radius: 12px;
    background: white;
    -webkit-box-shadow: -4px 7px 11px -8px rgba(0,0,0,0.62);
-moz-box-shadow: -4px 7px 11px -8px rgba(0,0,0,0.62);
box-shadow: -4px 7px 11px -8px rgba(0,0,0,0.62);
}
.direct-training-card-miniature{
    width:100%;
    height:180px;
}
.direct-training-card-miniature img{
    width: 100%;
    height:100%;
    object-fit: cover;
    border-radius: 12px;
    -webkit-box-shadow: -4px 7px 11px -8px rgba(0,0,0,0.62);
-moz-box-shadow: -4px 7px 11px -8px rgba(0,0,0,0.62);
box-shadow: -4px 7px 11px -8px rgba(0,0,0,0.62);
}

.course-card-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.course-card-info span{
    font-size: 14px;
}
.course-card-info svg{
    width: 14px;
}

.vert-spr{
    width: 2px;
    height:20px;
    background:black;
}
.course-card-length, .course-card-starting-date{
    display: flex;
    align-items: center;
    gap:10px;
}
.course-card-title{
    font-size:18px;
    font-weight: 700;
    text-align: center;
}
.direct-training-card-info{
    padding-top:5px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    gap:15px;
}

.direct-training-cards-content{
    display: flex;
    gap:30px;
    justify-content: center;
    flex-wrap: wrap;
}
.direct-training-cards-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px;
}


@media screen and (min-width: 1250px){

    .direct-training-section-elem:nth-child(3){
        padding:20px 0px !important;
    }
    .direct-training-card{
        margin-bottom: 20px;
    }
    .direct-training-section-content{
        display: flex;
        flex-direction: column;
        gap:20px;
    }
}
@media screen and (min-width: 1270px){
    .direct-training-cards-content{
        gap:1vw;
        justify-content: flex-start;
    }
}

@media screen and (min-width: 1335px){
    .direct-training-cards-content{
        width: 1100px;
    }
    .direct-training-section-elem-cards-container{
        width: 1100px !important;
        padding:20px 0px !important;
    }
    .direct-training-section-elem:nth-child(2){
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .direct-training-cards-content{
        gap:30px;
    }
    
}

@media screen and (min-width: 1440px){
    
    .direct-training-cards-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:30px;
    }
    .direct-training-cards-content{
        gap:30px !important;
    }

    .direct-training-body .card-wrapper-elem-content{
        gap:15px !important;
    }   

}

.pagination-dot{
    border-radius: 50% !important;
}

.cards-wrapper-text{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.direct-training-body .card-wrapper-elem-content{
    gap:5px;
}       
