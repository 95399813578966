.contact-us-form-container, .loca-container{
    padding: 20px;
}
.contact-us-form-content, .loca-content{
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 12px;
    gap:30px;
}
.contact-us-form-body{
    width: 100%;
}
.contact-us-form-icon-placeholder img{
    width:100px;
    height:100px;
}
.actual-location-container{
    margin:20px;
    background-color: #FBFBFB;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    width: 100%;
    height:300px;
    -webkit-box-shadow: 6px 8px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 6px 8px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 6px 8px 10px -9px rgba(0,0,0,0.32);
}
.actual-location-content{
    width: 96%;
    height: 96%;
    background-color: white;
    border-radius: 20px;
}

.user-profil-contact-form-container form{
    display: flex;
    flex-direction: column;
    gap:20px;
}

.contact-us-form-content form{
    width: 100% !important;
    font-size: 14px;
}
.contact-us-form-content form label{
    margin:0px;
    font-weight: 700;
}
.contact-us-form-row{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap:10px;
}



@media screen and (min-width: 900px){
    .user-profil-contact-form-container form{
        gap:30px;
    }
}
@media screen and (min-width: 600px){
    .contact-us-form-row{
        flex-direction: row;
        align-items: flex-start;
    }
    .input-field-label-container{
        width: 40%;
    }
    .contact-us-form-row:nth-child(4){
        justify-content: flex-end;
    }
    }

    @media screen and (min-width: 1100px){
        .contact-us-form-loca-content{
            display: flex;
            gap:20px;
        }
        .loca-container{
            width: 50%;
            padding:0px;
        }
        .loca-content{
            height:100%;
        }
        .contact-us-form-container{
            width:50%;
            padding:0px;
        }
        .contact-us-form-loca-content{
            padding:0px 20px;
        }
        .contact-us-form-loca-content{
            padding:40px 10px;
        }
        .actual-location-container{
            width: 70%;
        }
    }
    @media screen and (min-width: 1200px){
        .contact-us-form-loca-content{
            width:1100px;
        }
        .contact-us-form-loca-content{
            gap:40px;
        }
        .contact-us-form-loca-container{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    @media screen and (min-width: 1440px){
        .contact-us-form-loca-content{
            width:1300px;
        }
        .contact-us-form-loca-content{
            padding:80px 10px;
        }

    }
