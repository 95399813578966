@import './assets/main.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  color: black;
}
.Mui-selected {
  background: #ea0029 !important;
}
.MuiButtonBase-root {
  width: 35px !important;
  height: 35px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  -webkit-box-shadow: 0px 6px 16px -6px rgb(0 0 0 / 75%) !important;
  -moz-box-shadow: 0px 6px 16px -6px rgba(0, 0, 0, 0.75) !important;
  box-shadow: 0px 6px 16px -6px rgb(0 0 0 / 75%) !important;
  background: #ffffff;
} /*
.MuiTouchRipple-root {
  font-weight: 700 !important;
  color: black !important;
}*/
.Mui-selected > .MuiTouchRipple-root {
  font-weight: 700 !important;
  color: white !important;
}
.MuiPaginationItem-ellipsis {
  width: 48px;
  font-size: 27px;
}

.header-section {
  background-color: white;
}
.input-error {
  border: 1px solid #ede8e9 !important;
  border-bottom-color: #ff5b35 !important;
  border-left-color: #ff5b35 !important;
  border-top-color: #ff5b35 !important;
  border-right-color: #ff5b35 !important;
}

.ReactModal__Content {
  width: 78%;
  height: 80%;
}

.prev {
  margin-left: -5%;
}
.next {
  margin-right: -5%;
}
.react-player__preview {
  border-radius: 10px !important;
}

a {
  text-decoration: none !important;
}

body {
  font-family: 'Tajawal', sans-serif;
}

.main {
  background-image: url('./assets/img/diploma/dip_back.png');
  background-size: 100% 100%;
  height: 700px;
  width: 100%;
  display: table;
}
.center {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.title {
  letter-spacing: 2px;
}
.second {
  letter-spacing: 1px;
}
p span {
  font-size: 18px;
  font-weight: bold;
}
h3 {
  font-size: 60px;
}
.description {
  padding: 0% 16%;
  text-align: center;
}
table {
  margin: 0 auto;
}
.registered-user-view {
  padding: 0 3%;
}

.select_container_item {
  height: 30px;
  width: 487px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
}

.left_section {
  height: 100%;
  width: 50px;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  margin-top: 23px;
}

.sub_desc {
  width: 100%;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #eceef5;
  opacity: 0.9;
}

.right_section {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.toast_title {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  color: #ffffff;
  margin-top: 4px;
  margin-bottom: 6px;
}

.Toastify__close-button {
  margin-top: -8px;
  margin-bottom: 6px;
}

.toast_succ {
  background: #1dd2c1;
}

.toast_error {
  background: #ea0029;
}

.toast_warning {
  background: #f6933e;
}

.toast_info {
  background: #819cff;
}

.Toastify__toast-container {
  margin-bottom: 0 !important;
}

.Toastify__toast {
  height: 100px !important;
  border-radius: 5px !important;
}

.MuiAutocomplete-endAdornment {
  display: none !important;
}

.side {
  width: 21% !important;
}
.dashboard-column {
  width: 100% !important;
}

.tickLabel {
  transform: translate3d(-20px, 0px, 0px);
  font-size: 14px !important;
}

.ReactChart {
  height: 100% !important;
}

.apexcharts-canvas {
  width: 128px !important;
}
