.profil-header, .profil-body{
    background: #EDEDED;
}

.profil-container{
    padding:40px 0px;
}
.profil-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px;
}
.profil-column{
    width: 80%;
    display: flex;
    flex-direction: column;
    gap:20px;
}

.profil-column-elem{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
        display: flex;
        flex-direction: column;
        gap:30px;
        background: white;
        padding:20px 10px;
        border-radius: 12px;
        -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    }

.profil-img-placeholder{
    width: 100px;
    height: 100px;
    background: #FBFBFB;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profil-img-placeholder img{
    width:50%;
} 
.profil-img-placeholder-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.profil-user-info-text-content{
    display: flex;
    flex-direction: column;
    gap:10px;
}
.profil-user-info-text-content span{
    font-weight: 700;
}

.user-profil-account-link-title span,
.user-profil-cv-content-title span, 
.user-profil-account-link-title span{
    font-weight: 700;
}
.user-profil-account-link-container{
    width: 100%;
    padding:0px 20px;
}
.user-profil-account-link-body{
    background: #FBFBFB;
    padding:10px;
    text-align: left;
    border-radius: 12px;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
-moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
}
.user-profil-account-link-body span{
    color:#C2C2C2;
    font-weight: bold;
}
.user-profil-account-link-content{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.user-profil-cv-container{
    padding:0px 20px;
    width: 100%;
}
.user-profil-cv-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.user-profil-cv-content-body{
    width: 50px;
    height:50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background:#FBFBFB;
    border-radius: 12px;
    -webkit-box-shadow: 2px 3px 22px -10px rgba(0,0,0,0.29);
-moz-box-shadow: 2px 3px 22px -10px rgba(0,0,0,0.29);
box-shadow: 2px 3px 22px -10px rgba(0,0,0,0.29);
}
.user-profil-cv-content-body svg{
    width:50%;
    height:50%;
}
.user-profil-account-link-body-error{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FBFBFB;
    padding:10px 15px;
    text-align: left;
    border-radius: 12px;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
-moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
}
.user-profil-account-link-body-error span{
    color:#EA0029;
    font-weight: 700;
}

.close-user-profil-account-link-body-error:hover{
    cursor: pointer;
}
.profil-column-elem-title{
    width: 100%;
}
.profil-column-elem-content{
    width: 100%;
    padding:0px 20px;
}
.profil-column-elem-title{
    font-weight: 700;
}
.user-profil-new-pub-body{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FBFBFB;
    padding:10px 15px;
    border-radius: 12px;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
-moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
}
.user-profil-new-pub-body span{
    color:#EA0029;
    font-weight: 700;
}
.profil-column-elem-content{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.crs-certif{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FBFBFB;
    padding:10px 15px;
    border-radius: 12px;
    gap:10px;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
-moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
width: 100%;
}
.user-profil-courses-certif-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px;
}

.crs-certif .crs-certif-name span{
    color:#EA0029;
    font-weight: 700;
}   
.crs-certif-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.crs-certif-info span{
    color:#C2C2C2;
    font-weight: 700;
    font-size: 14px;
}
.vert-sepet{
    width:2px;
    height:13px;
    background: #C2C2C2;
}
.user-profil-scientific-qualifications-content li, .user-profil-scientific-qualifications-content span,
.user-profil-Academic-qualifications-content li, .user-profil-Academic-qualifications-content span{
    font-size: 14px;
}
.user-profil-scientific-qualifications-title, .user-profil-Academic-qualifications-title{
    color:#EA0029;
    font-weight: 700;
}
.user-profil-scientific-qualifications-content, .user-profil-Academic-qualifications-content{
    display: flex;
    flex-direction: column;
    gap:12px;
    background: #FBFBFB;
    padding:10px 20px 10px 15px;
    border-radius: 12px;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
}
.profil-column-elem-content{
    display: flex;
    flex-direction: column;
    gap:25px;
}
.user-profil-scientific-qualifications-body, .user-profil-Academic-qualifications-body{
    margin-right:10px;
}
.user-profil-scientific-qualifications-body li, .user-profil-Academic-qualifications-body li{
    margin-bottom: 10px;
    font-weight: 700;
    color:#5A5A5A;
}
.input-field-label-content{
    background: #FBFBFB;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
padding: 6.5px;
}
.input-field-content input{
    border: none;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background:none !important;
}

.user-profil-contact-form-content form{
    width: 100% !important;
    font-size: 14px;
}
.user-profil-contact-form-content form label{
    margin:0px;
    font-weight: 700;
}
.user-profil-contact-form-row{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap:10px;
}
.input-field-label-container{
    width:100%;
}
.input-field-container{
    width: 100%;
    border-radius: 12px;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
}

.input-field-content{
    padding:5px;
    border-radius: 12px;
    background: #FBFBFB;
}


.input-field-container input{
    width: 100%;
    font-size: 16px;
    padding:0px 18px;
}

/*
.input-field-container input:focus{
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
}*/

.user-profil-contact-form-container form{
    display: flex;
    flex-direction: column;
    gap:20px;
}

.input-field-container textarea{border: none; background: #FBFBFB;}
.input-field-container textarea:focus{
    border:none !important; 
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}



@media screen and (min-width: 600px){
.user-profil-contact-form-row{
    flex-direction: row;
    align-items: flex-start;
}
.input-field-label-container{
    width: 40%;
}
.user-profil-contact-form-row:nth-child(5){
    justify-content: flex-end;
}
}

@media screen and (min-width: 700px){
    .user-profil-courses-certif-content{
        flex-direction: row;
        justify-content: space-between;
    }
}

@media screen and (min-width: 750px){
    .profil-content{
        flex-direction: row;
        align-items: flex-start;
    }
    .profil-column:nth-child(1){
        width: 40%;
    }
    .profil-content{
        padding:0px 20px;
    }

}

@media screen and (min-width: 900px){
    .user-profil-contact-form-container form{
        gap:30px;
    }
}
@media screen and (min-width: 1000px){
    .profil-container{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .profil-content{
        width:950px;
    }
    .profil-column:nth-child(1){
        width: 30%;
    }
    .crs-certif{
        width: 250px;
    }
    .profil-container{
        padding:40px 0px 80px 0px;
    }

}
@media screen and (min-width: 1200px){
    .profil-content{
        width:1100px;
    }
    .input-field-label-container{
        width: 30%;
    }
    .profil-column:nth-child(1) .profil-column-elem:nth-child(1){
        height:318px;
    }
    .profil-img-placeholder{
        width: 130px;
        height:130px;
    }
}
@media screen and (min-width: 1600px){
    .profil-content{
        width:1400px;
    }

}

textarea {
    resize: none;
  }
  textarea:focus {
    background: #FBFBFB !important;
  }