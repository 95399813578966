.memberApp-form-header, .memberApp-form-body,
.institutionApp-form-header, .institutionApp-form-body,
.instructorApp-form-header, .instructorApp-form-body{
    background: #EDEDED;
}

.membership-benefits-conditions-container{
    background: white;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    margin: 0px 30px;
    border-radius: 12px;
}

.membership-benefits-conditions-point{
    width:8px;
    height:8px;
    border-radius: 50%;
    background: #00732F;
}

.membership-benefits-conditions-content
{
    padding-right: 20px;
}

li{
    font-weight: 700;
}
li::marker {
    color: #00732F;
    font-size: 18px;
  }
.membership-benefits-conditions-body{
    width: 90%;
}
.membership-benefits-conditions-body ul{
    display: flex;
    flex-direction: column;
    gap:10px;
    margin-right: 20px;
}
.membership-benefits-conditions-container{
    padding: 20px;
   
}
.form-row-group{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.app-form--body{
    padding:40px;
 
}

.memberApp-form-body .input-field-label-content, .memberApp-form-body .input-field-content{
    background: white;
}
.app-form--body form{
    display: flex;
    flex-direction: column;
    gap:20px;
    font-weight: 700;
}
.app-form--body form .btn-green{
    width:100%;
    margin:10px;
    border-radius: 12px;
}
.app-form--body form .input-field-label-content{
    padding:6.5px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.app-form--body form label{
    margin: 0px !important;
}

.app-form--content{
    padding:40px 0px;
}
.membership-benefits-conditions-header{
    font-weight: 700;
    font-size: 20px;
}

.membership-benefits-conditions-content{
    display: flex;
    flex-direction: column;
    gap:10px;
}
@media screen and (min-width: 600px){
    .app-form--body form{
        width:100% !important;
    }
    .app-form--body .user-profil-contact-form-row{
        flex-direction: row;
    }
    .app-form--body .input-field-label-container{
        width: 100%;
    }
    .app-form--body .form-row-group:nth-child(1) .input-field-label-container{
        width:40%;
    }
    .app-form--body .user-profil-contact-form-row button{
        
        margin: 0px;
        padding:10px 20px;
        font-size: 14px;
    }
    .app-form--body .user-profil-contact-form-row  button{
        margin-top:20px;
    }
}
@media screen and (min-width: 900px){
    .app-form--content{
        display: flex;
    justify-content: space-between;     
    }
    .membership-benefits-conditions-container{
        width:40%;
    }
    .app-form--body{
        padding:0px 20px;
    }
    .app-form--body .input-field-label-content label{
        font-size: 14px;
    }
    .app-form--body{
        width: 70%;
    }
    .form-row-group:nth-child(2){
        margin-top: 30px;
    }
}
@media screen and (min-width: 1100px){
    .app-form--content{
        width: 1000px;
    }
    .app-form--container{
        display: flex;
        justify-content: center;
    }

    .memebership-conds-terms{
        margin-top: 10px;
    }
    .app-form--content{
        padding-bottom: 80px;
    }
    .membership-benefits-conditions-body li{
        font-size: 18px;
    }
    li::marker {
        font-size: 20px;
      }
}
@media screen and (min-width: 1300px){
    .app-form--content{
        width: 1200px;
    }
     .app-form--body .input-field-label-content label, .app-form--body .input-field-content input{
        font-size: 16px;
    }
    .app-form--body .input-field-label-container .input-field-label-content{
        padding:5px !important;
    }
    .app-form--body{
        width: 50%;
    }

}