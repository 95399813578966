
.auth-main-section .input-field-container input{
    padding:10px 18px;
}
.auth-main-section .checkbox-button{
    height: 16px;
}

.signup-form-container, .signin-form-container{
    height:100vh;
    background: #FBFBFB;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.signup-form-content, .signin-form-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FBFBFB;
    gap:20px;
    padding:20px 0px;
    width: 100%;
}
.signup-form-content form, .signin-form-content form{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap:10px;
}
.input-container{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.signup-form-content button, .signin-form-content button{
    padding:10px 25px !important;
    width: 100%;
    border-radius: 15px;
}
.input-container input:focus{
    -webkit-box-shadow: 2px 4px 23px -15px rgba(0,0,0,0.73) !important;
-moz-box-shadow: 2px 4px 23px -15px rgba(0,0,0,0.73) !important;
box-shadow: 2px 4px 23px -15px rgba(0,0,0,0.73) !important;
}
.input-container input{
    text-align: right;
    width: 100%;
    border: none;
    border-radius: 15px;
    background: white;
    padding:10px 25px;
    -webkit-box-shadow: 2px 4px 23px -15px rgba(0,0,0,0.73);
-moz-box-shadow: 2px 4px 23px -15px rgba(0,0,0,0.73);
box-shadow: 2px 4px 23px -15px rgba(0,0,0,0.73);
    font-weight: 700;
}

.-form-header--title span{
    font-size: 20px;
    color:#00732F;
    font-weight: 700;
}
.-form-header--text-desc span{
    font-size: 14px;
    color:black;
    font-weight: 700;
}
.form-header{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:10px;
}
.-form-header--text-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.-form-header--icon-container{
    width: 70px;
    height:70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    -webkit-box-shadow: 2px 4px 23px -15px rgba(0,0,0,0.73);
-moz-box-shadow: 2px 4px 23px -15px rgba(0,0,0,0.73);
box-shadow: 2px 4px 23px -15px rgba(0,0,0,0.73);
border-radius: 50%;
}
.-form-header--icon-container img{
    width: 50%;
    height:50%;
    object-fit: contain;
}

.signup-form-content .-signIn--link{
    margin-top: 10px;}

.-signIn--link{
    display: flex;
    align-items: flex-end;
}
.-signIn--link a{
    font-weight: 700;
    color:black;
}
.-signIn--link a:hover{
    text-decoration: none;
}
form .btn{
    margin-top:10px;
}

@media screen and (min-width: 600px){
    .signup-form-content, .signin-form-content{
        width: 500px;
        border-radius: 20px;
        gap:40px;
    }
    form{
        width: 70% !important;
    }
    .signup-form-container, .signin-form-container{
        background:#B4B4B4;
    }
}

@media screen and (min-width: 1440px){
    .-form-header--icon-container{
        width: 100px;
        height: 100px;
    }
    .signup-form-content, .signin-form-content{
        width: 569px;
    }
    form{
        gap:30px !important;
    }
    .-form-header--title span{
        font-size: 28px;

    }
    .-form-header--text-desc span{
        font-size: 18px;
    }
    .-form-header--text-container{
        gap:10px;
    }
    .signup-form-content, .signin-form-content{
        padding-top:10px;
        padding-bottom:10px;
    }
}

.-user-email-header{
    display: flex;
    flex-direction: row-reverse;
    gap:10px;
    align-items: center;
    justify-content: flex-end;
    padding-bottom:15px;
}
.-user-email-header{
    font-weight: 700;
    font-size: 14px;
}


.btns-links{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}
.btns-links{
    margin-top:25px;
}
.remember-me-btn-container span{
    font-weight:700;
}

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 18px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checkmark{
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 3px #0275d8;
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px #0275d8;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color:rgb(223, 223, 223);
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 28px -10px rgba(0,0,0,0.7) !important;
    -moz-box-shadow: 0px 0px 28px -10px rgba(0,0,0,0.7) !important;
    box-shadow: 0px 0px 28px -10px rgba(0,0,0,0.7) !important;
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

.remember-me-btn-container{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
}