.admin-dashboard-view-content{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.admin-dashboard-view-content .dashboard-row{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:40px;
}
.admin-dashboard-view-content .dashboard-row .btn-red{
    display: flex;
    align-items: center;
    gap:15px;
    border-radius: 8px;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
}
.admin-dashboard-view-content .dashboard-column-content-header-content{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:10px;
    border-radius: 8px;
}

.admin-dashboard-view-content .-red--ver .dashboard-column-content-header-content{
    background-color: #EA0029;
}
.admin-dashboard-view-content .-gray--ver .dashboard-column-content-header-content{
    background-color: #5A5A5A;
}
.admin-dashboard-view-content .-green--ver .dashboard-column-content-header-content{
    background-color: #00732F;
}

.admin-dashboard-view-content .dashboard-column-content-header-content{
color:white;
font-weight: 700;
}

.admin-dashboard-view-content .user-icon-container{
    width: 80px;
    height:80px;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-dashboard-view-content .user-icon-container img{
    width: 50%;
    height:50%;
    object-fit: contain;
}
.admin-dashboard-view-content .registered-user-info{
    display: flex;
    flex-direction: column;
    gap:5px;
    font-weight: 600;
}
.admin-dashboard-view-content .dashboard-column-content-body-elem{
    background: #FBFBFB;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap:20px;
    padding:10px;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
}
.admin-dashboard-view-content .dshb-user-status{
    opacity:0.5;
    font-size: 14px;
}
.admin-dashboard-view-content .dashboard-column-content-body{
    background: white;
    border-radius: 8px;
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
}
.admin-dashboard-view-content .dashboard-column-content{
    gap:20px !important;
}
.admin-dashboard-view-content .dashboard-column-content-body-content{
    display: flex;
    flex-direction: column;
    gap:10px;
}
.admin-dashboard-view-content .dashboard-column-down-arrow{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:10px 10px 5px 10px;
}
.admin-dashboard-view-content .dashboard-row .dashboard-column{
/*height: 100%;
width: 33%;*/
width: 100%;
}
.admin-dashboard-view-content .dashboard-column-content .dashboard-column-content-header{
    -webkit-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    -moz-box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
    box-shadow: 2px 7px 10px -9px rgba(0,0,0,0.32);
}

@media screen and (min-width: 800px) {
    .admin-dashboard-view-content .dashboard-row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .admin-dashboard-view-content .dashboard-row .dashboard-column{
        width: 47%;
    }
}
@media screen and (min-width: 1200px) {
    .admin-dashboard-view-content .dashboard-row{
        flex-wrap: nowrap;
    }
    .admin-dashboard-view-content .dashboard-row .dashboard-column{
        width: 33%;
    }
    .admin-dashboard-view-content .dashboard-row{
        gap:20px;
    }
}
@media screen and (min-width: 1440px) {
    .admin-view-dashboard,
    .admin-dashboard-view-content{
        height:100%;
    }
    .admin-dashboard-view-content .dashboard-row .dashboard-column{
        width: 33%;
        height:100%;
    }
    .admin-dashboard-view-content{
        gap:15px;
    }
}
@media screen and (min-width: 1600px) {
    .admin-dashboard-view-content{
        gap:35px;
    }
}
@media screen and (min-width: 600px) {
.cardes-wrapper-content, .photos-ressources-body .cardes-wrapper-content {
    width: 500px !important;
    gap: 10px;
    justify-content: flex-start;
}
.cardes-wrapper-content .card-wrapper-elem-content{
    width: 220px !important;
    height:230px !important;
}
}
.cardes-wrapper-content, .photos-ressources-body .cardes-wrapper-content {
    width: 250px;
    gap: 20px;
    justify-content: center;
}

@media screen and (min-width: 800px) {
    .cardes-wrapper-content, .photos-ressources-body .cardes-wrapper-content {
        width: 720px !important;
        gap: 10px;
        justify-content: flex-start;
    }
}

@media screen and (min-width: 1000px) {
    .cardes-wrapper-content, .photos-ressources-body .cardes-wrapper-content {
        width: 850px !important;
        gap: 10px;
        justify-content: flex-start;
    }
    .admin-view-dashboard .cards-wrapper-img{
        width: 130px !important;
        height: 130px !important;
    }
    .admin-view-dashboard .cards-wrapper-elem{
        width: 220px !important;
        height:230px !important;
    }
    .admin-view-dashboard .dashboard-row .cardes-wrapper-content {
        gap:95px !important;
    }
}
@media screen and (min-width: 1200px) {
    .admin-view-dashboard .dashboard-row .cardes-wrapper-content {
        gap:40px !important;
    }
    .cardes-wrapper-content, .photos-ressources-body .cardes-wrapper-content {
        width: 1000px !important;
        gap: 10px;
        justify-content: flex-start;
    }
}


@media screen and (min-width: 1440px) {
    .admin-view-dashboard .dashboard-row .cardes-wrapper-content {
        gap:40px !important;
    }
    .cardes-wrapper-content, .photos-ressources-body .cardes-wrapper-content {
        width: 1000px !important;
        gap: 10px;
        justify-content: flex-start;
    }
}