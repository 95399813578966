.course-general-info {
  width: 80%;
  height: 300px;
  background: white;
  border-radius: 12px;
  -webkit-box-shadow: 3px 3px 9px 1px rgb(0 0 0 / 22%);
  -moz-box-shadow: 3px 3px 9px 1px rgba(0, 0, 0, 0.22);
  box-shadow: 3px 3px 9px 1px rgb(0 0 0 / 22%);
}
.course-miniature {
  width: 100%;
  height: 70%;
}
.course-miniature img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: contain;
  -webkit-box-shadow: 3px 3px 9px 1px rgb(0 0 0 / 22%);
  -moz-box-shadow: 3px 3px 9px 1px rgba(0, 0, 0, 0.22);
  box-shadow: 3px 3px 9px 1px rgb(0 0 0 / 22%);
}
.course-title span,
.course-length span {
  font-size: 15px;
  font-weight: 700;
}
.course-length {
  display: flex;
  align-items: center;
  gap: 10px;
}
.vert-seper {
  height: 15px;
  width: 2px;
  background: black;
}
.course-general-info-content {
  height: 100%;
}
.btn-green {
  background: #00732f;
  color: white;
  font-weight: 700;
  font-size: 13px !important;
  padding: 5px 20px;
}
.course-info-ratings {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 20%;
  justify-content: space-between;
}
.course-info-regist-container {
  display: flex;
  justify-content: center;
  height: 45%;
}
.course-info-regist-content {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
}
.course-rating {
  display: flex;
  align-items: center;
}
.course-rating img {
  width: 21px;
}

.register-course-btn {
  width: 80%;
}
.register-course-btn button {
  width: 100%;
}

.course-description-container {
  background: white;
  width: 80%;
  -webkit-box-shadow: 3px 3px 9px 1px rgb(0 0 0 / 22%);
  -moz-box-shadow: 3px 3px 9px 1px rgba(0, 0, 0, 0.22);
  box-shadow: 3px 3px 9px 1px rgb(0 0 0 / 22%);
}
.course-description-content {
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
}
.course-info-desc {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}
.course-description-container {
  padding: 20px;
  border-radius: 12px;
}
.course-summary-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.course-summary-content {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.course-summary-header-elem {
  display: flex;
  background: white;
  border-radius: 12px;
  -webkit-box-shadow: 3px 3px 9px 1px rgb(0 0 0 / 22%);
  -moz-box-shadow: 3px 3px 9px 1px rgba(0, 0, 0, 0.22);
  box-shadow: 3px 3px 9px 1px rgb(0 0 0 / 22%);
}
.course-summary-header-elem span {
  color: black;
  padding: 10px;
  font-weight: 700;
  padding-right: 15px;
}
.course-summary-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.course-summary-header-title {
  background: #5a5a5a;
  margin-bottom: 20px;
}
.course-summary-header-title span {
  color: white;
  padding-right: 40px;
}

.course-summary-body-elem-title-content {
  background: #ea0029;
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 5px;
}
.course-summary-body-elem-title span {
  color: white;
}
.course-summary-body-elem-body .course-summary-subsections-elem:first-child {
  background: #ea0029;
  color: white;
  font-weight: 600;
}
.course-summary-body-elem-body .course-summary-subsections-elem {
  background: white;
  color: black;
  font-weight: 600;
  border-radius: 12px;
  padding: 10px;
}
.course-summary-body-elem-body .course-summary-subsections-elem:last-child {
  background: #00732f;
  color: white;
}
.course-summary-body-elem-body .course-summary-subsections-elem:last-child svg g {
  stroke: #fff;
}
.course-summary-body-elem-body .course-summary-subsections-elem:last-child svg path {
  stroke: #fff;
}
.course-summary-body-elem-body .course-summary-subsections-elem:last-child svg g line {
  stroke: #fff;
}

.course-summary-body-elem {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.course-summary-body-elem-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.recap-section-elem .course-summary-subsections-elem:not(:first-child) {
  background: #dee0e6;
  color: black !important;
}
.recap-section-elem .course-summary-subsections-elem:not(:first-child) svg g {
  stroke: #000;
}
.recap-section-elem .course-summary-subsections-elem:not(:first-child) svg path {
  stroke: #000;
}
.recap-section-elem .course-summary-subsections-elem:not(:first-child) svg g line {
  stroke: #000;
}

.conclusion-section-elem .course-summary-subsections-elem:not(:first-child) {
  background: #5a5a5a;
  color: white !important;
}

.course-summary-body-elem-title-content,
.course-summary-subsections-elem {
  -webkit-box-shadow: 3px 3px 9px 1px rgb(0 0 0 / 22%);
  -moz-box-shadow: 3px 3px 9px 1px rgba(0, 0, 0, 0.22);
  box-shadow: 3px 3px 9px 1px rgb(0 0 0 / 22%);
}

.course-summary-body-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.course-catalogue-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.course-description-content span {
  font-weight: 700;
}
@media screen and (min-width: 650px) {
  .course-general-info {
    width: 40%;
    height: 350px;
  }
  .course-description-container {
    width: 60%;
    min-height: 350px;
  }
  .course-info-desc {
    flex-direction: row;
    gap: 30px;
  }
  .course-description-content span {
    --max-lines: 3;
    position: relative;
    overflow: hidden;
    padding-right: 1rem; /* space for ellipsis */
    line-height: 2em;
  }
  .course-summary-content {
    width: 90%;
  }
  .course-info-desc {
    width: 90%;
  }
  .course-catalogue-elem:nth-child(1) {
    display: flex;
    justify-content: center;
  }
  .course-info-regist-container {
    height: 50%;
    padding-bottom: 18.5px;
  }
  .course-description-content span {
    line-height: 1.7em;
  }
  .course-description-content {
    height: 100%;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1000px) {
  .course-general-info {
    width: 25%;
  }
  .course-description-container {
    width: 75%;
  }
  .course-description-content {
    height: 100%;
  }
  .course-description-content {
    justify-content: space-between;
  }
  .course-description-content button {
    width: 120px !important;
  }
  .course-summary-body-elem {
    flex-direction: row;
  }
  .course-summary-body-elem-title {
    width: 20%;
  }
  .course-summary-body-elem-body {
    width: 80%;
  }
  .course-summary-body-elem-title-content {
    border-radius: 12px;
    display: flex;
    justify-content: center;
  }
  .course-summary-body-elem-title-content span {
    font-weight: 700;
  }
  .course-summary-subsections-elem span {
    margin-right: 20px;
  }
  .course-summary-subsections-elem,
  .course-summary-body-elem-title-content {
    height: 40px;
    display: flex;
    padding: 0px !important;
    align-items: center;
  }
  .course-summary-body-elem-body {
    gap: 15px;
  }
  .course-catalogue-content {
    gap: 80px;
  }
  .course-info-desc {
    gap: 50px;
  }
  .course-description-content span {
    font-size: 20px;
  }
  .course-summary-header {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1300px) {
  .course-catalogue-content,
  .course-catalogue-container {
    width: 1100px;
  }
  .course-catalogue-container {
    display: flex;
    align-items: center;
  }
  .courses-body .bs-main-content {
    display: flex;
    justify-content: center;
  }
}

.course-catalogue-content {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media screen and (min-width: 1440px) {
  .course-catalogue-content,
  .course-catalogue-container {
    width: 1200px;
  }
  .course-summary-container span {
    font-size: 16px;
  }
  .course-summary-header {
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 1600px) {
  .course-catalogue-content,
  .course-catalogue-container {
    width: 1400px;
  }
  .course-summary-container span {
    font-size: 18px;
  }
}

.btn-green:hover {
  color: white;
}
.btn-green {
  -webkit-box-shadow: 2px 7px 14px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 7px 14px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 7px 14px -12px rgba(0, 0, 0, 0.75);
}

.knowledge-certif-content {
  font-family: 'Mulish', sans-serif;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.certif-title {
  font-size: 22px;
}
.certif-subtitle,
.knowledge-certif-content-body-head {
  font-size: 10px;
}
.knowledge-certif-content .horz-spr {
  width: 60%;
  height: 2px;
  background-color: #fda623;
}
.certif-owner-name {
  font-size: 49px;
}
.certif-desc {
  font-size: 7px;
  text-align: center;
  margin-top: 10px;
}
.knowledge-certif-content-header,
.knowledge-certif-content-body,
.certif-date {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.certif-date {
  gap: 10px;
}
.certif-actual-date {
  font-size: 13px;
}
.certif-date-spr {
  height: 1px;
  width: 100px;
  background: black;
}
.certif-manager-signature-spr {
  height: 1px;
  width: 120px;
  background: black;
}
.certif-date-txt {
  font-size: 9px;
}
.certif-badge-cont {
  position: relative;
}
.certif-award-badge-content {
  position: absolute;
  top: 28px;
  left: 22px;
}
.certif-award-badge-year span {
  font-size: 26px !important;
}
.certif-award-badge-content span {
  font-size: 9px;
}
.certif-award-badge-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.certif-manager-signature img {
  width: 90px !important;
  height: auto;
}
.certif-manager-signature span {
  font-size: 9px;
}
.certif-manager-signature {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 23px;
}
.knowledge-certif-content-footer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}
.knowledge-certif-content {
  padding: 42px 80px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.knowledge-certif {
  position: relative;
}

@media screen and (max-width: 420px) {
  .certif-title,
  .certif-actual-date {
    font-size: 8px !important;
  }
  .certif-subtitle,
  .knowledge-certif-content-body-head {
    font-size: 4px !important;
  }
  .certif-owner-name {
    font-size: 14px;
  }
  .certif-desc {
    font-size: 3px;
  }
  .certif-date-spr {
    width: 40px;
  }
  .certif-date {
    gap: 2px;
  }
  .certif-date span {
    font-size: 5px !important;
  }
  .certif-date-spr {
    width: 30px;
  }
  .certif-badge-cont svg {
    width: 40px;
    height: 41px;
  }
  .certif-award-badge-year span {
    font-size: 10px !important;
  }
  .certif-award-badge-content span {
    font-size: 4px;
  }
  .certif-award-badge-content {
    top: 4px;
    left: 9px;
  }
  .certif-manager-signature img {
    width: 30px !important;
  }
  .certif-manager-signature-spr {
    width: 40px;
  }
  .certif-manager-signature span {
    font-size: 4px !important;
  }
  .certif-manager-signature {
    margin-bottom: 1.7vw !important;
    gap: 2px;
  }
  .knowledge-certif-content {
    padding: 40px;
  }
  .knowledge-certif-content-footer {
    padding: 0px 15px;
  }
}

@media screen and (min-width: 420px) and (max-width: 600px) {
  .certif-title,
  .certif-actual-date {
    font-size: 10px !important;
  }
  .certif-subtitle,
  .knowledge-certif-content-body-head {
    font-size: 6px !important;
  }
  .certif-owner-name {
    font-size: 17px;
  }
  .certif-desc {
    font-size: 5px;
  }
  .certif-date-spr {
    width: 40px;
  }
  .certif-date {
    gap: 3px;
  }
  .certif-date span {
    font-size: 5px !important;
  }
  .certif-date-spr {
    width: 35px;
  }
  .certif-badge-cont svg {
    width: 45px;
    height: 46px;
  }
  .certif-award-badge-year span {
    font-size: 10px !important;
  }
  .certif-award-badge-content span {
    font-size: 4px;
  }
  .certif-award-badge-content {
    top: 4px;
    left: 11px;
  }
  .certif-manager-signature img {
    width: 40px !important;
  }
  .certif-manager-signature-spr {
    width: 50px;
  }
  .certif-manager-signature span {
    font-size: 5px !important;
  }
  .certif-manager-signature {
    margin-bottom: 2.1vw !important;
    gap: 3px;
  }
  .knowledge-certif-content {
    padding: 40px;
  }
  .knowledge-certif-content-footer {
    padding: 0px 15px;
  }
}

@media screen and (min-width: 530px) and (max-width: 640px) {
  .knowledge-certif-content {
    padding: 40px 60px;
  }
}

@media screen and (min-width: 600px) and (max-width: 850px) {
  .certif-badge-cont svg {
    width: 45px;
    height: 46px;
  }
  .certif-actual-date,
  .certif-manager-signature span {
    font-size: 10px !important;
  }
  .certif-date {
    gap: 3px;
  }
  .certif-manager-signature img {
    width: 60px !important;
  }
  .certif-date-spr {
    width: 60px;
  }
  .certif-owner-name {
    font-size: 25px;
  }
  .certif-manager-signature-spr {
    width: 80px;
  }
  .certif-badge-cont svg {
    width: 60px;
    height: 61px;
  }
  .certif-award-badge-year span {
    font-size: 15px !important;
  }
  .certif-award-badge-content span {
    font-size: 6px;
  }
  .certif-award-badge-content {
    top: 12px;
    left: 13px;
  }
  .certif-manager-signature {
    margin-bottom: 10px !important;
    gap: 3px;
  }
  .knowledge-certif-content-footer {
    padding: 0px 20px;
  }
  .certif-manager-signature {
    margin-bottom: 6px !important;
  }
}

@media screen and (min-width: 700px) and (max-width: 850px) {
  .knowledge-certif-content {
    gap: 25px !important;
  }
  .knowledge-certif-content-body {
    gap: 10px;
  }
}
@media screen and (min-width: 850px) and (max-width: 1000px) {
  .knowledge-certif-content {
    gap: 40px;
  }
  .knowledge-certif-content-footer {
    padding: 0px 40px;
  }
  .certif-date-spr {
    width: 70px !important;
  }
  .certif-date-txt,
  .certif-manager-signature span {
    font-size: 12px !important;
  }
  .certif-award-badge,
  .certif-manager-signature,
  .certif-date {
    width: 120px !important;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .certif-title,
  .certif-actual-date {
    font-size: 10px !important;
  }
  .certif-subtitle,
  .knowledge-certif-content-body-head {
    font-size: 6px !important;
  }
  .certif-owner-name {
    font-size: 17px;
  }
  .certif-desc {
    font-size: 5px;
  }
  .certif-date-spr {
    width: 40px;
  }
  .certif-date {
    gap: 3px;
  }
  .certif-date span {
    font-size: 5px !important;
  }
  .certif-date-spr {
    width: 35px;
  }
  .certif-badge-cont svg {
    width: 45px;
    height: 46px;
  }
  .certif-award-badge-year span {
    font-size: 10px !important;
  }
  .certif-award-badge-content span {
    font-size: 4px;
  }
  .certif-award-badge-content {
    top: 4px;
    left: 11px;
  }
  .certif-manager-signature img {
    width: 40px !important;
  }
  .certif-manager-signature-spr {
    width: 50px;
  }
  .certif-manager-signature span {
    font-size: 5px !important;
  }
  .certif-manager-signature {
    margin-bottom: 2.1vw !important;
    gap: 3px;
  }
  .knowledge-certif-content {
    padding: 40px;
  }
  .knowledge-certif-content-footer {
    padding: 0px 15px;
  }
  .certif-award-badge {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .certif-manager-signature {
    margin-bottom: 8px !important;
  }
  .certif-date-spr {
    width: 40px !important;
  }
  .knowledge-certif-content {
    padding: 40px 80px !important;
  }
  .knowledge-certif-content {
    gap: 30px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1000px) {
  .course-summary {
    width: 100% !important;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1440px) {
  .certif-title,
  .certif-actual-date {
    font-size: 10px;
  }
  .certif-subtitle,
  .knowledge-certif-content-body-head {
    font-size: 6px;
  }
  .certif-owner-name {
    font-size: 25px;
  }
  .certif-desc {
    font-size: 8px;
  }
  .certif-date-spr {
    width: 40px;
  }
  .knowledge-certif-content-body {
    gap: 10px;
  }
  .certif-date {
    gap: 3px;
  }
  .certif-date-spr {
    width: 35px;
  }
  .certif-badge-cont svg {
    width: 45px;
    height: 46px;
  }
  .certif-award-badge-year span {
    font-size: 10px !important;
  }
  .certif-award-badge-content span {
    font-size: 4px;
  }
  .certif-award-badge-content {
    top: 16px;
    left: 16px;
  }
  .certif-manager-signature img {
    width: 40px !important;
  }
  .certif-manager-signature-spr {
    width: 50px;
  }
  .certif-manager-signature span {
    font-size: 5px !important;
  }
  .certif-manager-signature {
    margin-bottom: 2.1vw !important;
    gap: 3px;
  }
  .knowledge-certif-content {
    padding: 40px;
  }
  .knowledge-certif-content-footer {
    padding: 0px 15px;
  }
  .certif-award-badge {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .certif-manager-signature {
    margin-bottom: 15px !important;
  }
  .certif-date-spr {
    width: 40px !important;
  }
  .knowledge-certif-content {
    padding: 40px 80px !important;
  }
  .knowledge-certif-content {
    gap: 30px;
  }
  .certif-title {
    font-size: 16px !important;
  }
  .certif-subtitle,
  .knowledge-certif-content-body-head span {
    font-size: 7px !important;
  }
  .knowledge-certif-content {
    gap: 40px;
  }
  .knowledge-certif-content-footer {
    padding: 0px 20px !important;
  }
  .certif-badge-cont svg {
    width: 70px;
    height: 70px;
  }
  .certif-award-badge-year span {
    font-size: 16px !important;
  }
  .award-txt {
    font-size: 6px !important;
  }
  .certif-date span {
    font-size: 8px !important;
  }
  .certif-manager-signature img {
    width: 60px !important;
  }
  .certif-manager-signature span {
    font-size: 8px !important;
  }
  .certif-award-badge,
  .certif-manager-signature,
  .certif-date {
    width: 120px !important;
  }
  .certif-manager-signature-spr {
    width: 80px;
  }
  .certif-manager-signature,
  .certif-date {
    gap: 8px;
  }
}

@media screen and (min-width: 1440px) {
  .knowledge-certif-content-footer {
    padding: 0px 30px;
  }
  .certif-date-spr {
    width: 75px !important;
  }
  .knowledge-certif-content {
    gap: 30px;
  }
  .certif-award-badge,
  .certif-manager-signature,
  .certif-date {
    width: 120px !important;
  }
}
