.user-personal-info-section .dashboard-body .dashboard-column{
    width:100% !important;
}
.user-personal-info .overall-progress-stats-elem{
    background: #EA0029;
    border-radius: 12px;
}

.-user--info-S1 .perc-in-number span{
    color:white;
    font-size: 35px !important;
}
.profil-completion-txt span{
    color:white !important;
    font-size: 16px !important;
    font-weight: 700;
}

.-white-stats--bar svg .bar{
    stroke: white !important;
  }

.profil-completion-perc .overall-progress-stat-perc-ring-content{
    width: 130px !important;
    height: 130px !important;
}
.profil-completion-perc .overall-progress-stats-elem{
    width: auto !important;
    height:85%;
}
.change-user-profil-pwd-content {
    background:white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:10px 15px;
    border-radius: 12px;
}
.download-user-personal-info-btn button{
    border: none;
    background: none;
}
.user-personal-info-form-art{
    position:relative;
    width: 220px;
}
.user-personal-info-form-art-container svg, .download-user-personal-info-btn button svg{
    width: 100%;
    height:100%;
}
.download-user-personal-info-btn{
    position: absolute;
    bottom: 0px;
    width: 70px;
}
.dashboard-user-personal-info-row{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap:10px;
}
.user-personal-info-form-container{
    display: flex;
    align-items: flex-end;
    background-color: white;
    border-radius: 12px;
    gap:40px;
    padding:25px;
}
.user-personal-info-form-body-row span{
    font-weight: 700;
    font-size: 16px;
}
.user-personal-info-form-body-row{
    display: flex;
    align-items: center;
    gap:15px;
}
.user-personal-info-form-container, .dashboard-stats-content, .dashboard-user-personal-info-row{
    width: 100%;
}

.user-personal-info-form-content{
    width: 100%;
}
.profil-completion-perc{
    width: 25%;
    height:100%;
    display: flex;
    flex-direction: column;
    gap:13px;
}
.user-info-input-field{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border:2px #DEE0E6 solid;
    border-radius: 12px;
    padding:5px 12px;
}
.user-personal-info-form-body{
    display: flex;
    flex-direction: column;
    gap:15px;
}
.user-personal-info-form-header{
    font-weight: 700;
    font-size: 20px;
}

.user-personal-info-form-header{
    font-weight: 700;
    font-size: 20px;
}
.user-personal-info-form-header{
    padding:20px 0px;
}

@media screen and (min-width: 1300px){
    .user-personal-info-form-content{
        width: 500px;
    }
    
    .user-academic-info{
        width: 500px;
    }
    .user-academic-info img{
        width: 100%;
        height:100%;
    } 
    .user-personal-info-section .sidebar-container{
        height: 600px !important;
    }
}

@media screen and (max-width: 900px){
   .dashboard-user-personal-info-row{
       flex-direction: column;
   } 
   .profil-completion-perc{
       width: 100%;
       height:300px;
   }
   .user-personal-info-form-container{
       flex-direction: column;
   }
   .user-personal-info-form-body-row{
        flex-direction: column;
   }
   .user-info-input-field span{
       font-size: 16px;
   }
   .user-personal-info-form-body-row .user-info-input-field span{
    font-size: 20px !important;
   }
   .user-personal-info-form-container{
       align-items: center;
   }
}

.user-info-input-field select{
    width: 85%;
}
.user-info-input-field select, .user-info-input-field input, .user-info-input-field textarea{
    border: none;
    font-weight: 700;
}
.input-text-cont{
    display: flex;
    align-items: center;
    gap: 15px; 
}

.input-text-cont input{
    width: 60%;
}
.user-info-input-field textarea{
    width: 85%;
    height: 100px;
}
.user-info-input-field textarea:focus{
    border: none !important;
    outline: none !important;
    background: none !important;
}

.details-txt-area .user-info-input-field{
    align-items: flex-start;
}
.details-txt-area .user-info-input-field svg{
    margin:15px 0px;
}
.user-academic-info-form-art{
    display: none;
}
.download-cv-btn{
    border-radius: 12px;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.checkbox-container span{
    font-size: 16px;
    font-weight: 700;
}
.checkbox-circle{
    width: 15px;
    height:15px;
    border-radius: 50%;
    border: 1px solid #00732F;
}
.checkbox-content{
    display: flex;
    align-items: center;
    gap:15px;
}
.change-app-language select{
    border: none;
    background: none;
}
.change-app-language{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:15px;
}
.save-btn-container, .save-btn-container button{
    width: 100%;
}
.save-btn-container button{
    font-size: 16px !important;
    padding:8px 0px;
}
.dashboard-user-personal-info-row{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.language-and-save-btn{
    background:white;
    padding:20px;
    border-radius: 12px;
}
.change-app-language span{
    font-weight: 700;
}
@media screen and (min-width: 600px){
.user-personal-info-form-body-row{
    flex-direction: row;
}
.change-app-language{
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.language-select-container{
    width: 50%;
}
}

@media screen and (min-width: 900px) and (max-width: 1000px) {
    .profil-completion-perc{
        width: 100% !important;
    }
    .profil-completion-perc .overall-progress-stats-elem {
        padding:25px;
    }
}
@media screen and (min-width: 1000px) and (max-width: 1300px) {
    .dashboard-user-personal-info-row{
        height:317px !important;
    }
}

@media screen and (min-width: 900px){
    .user-personal-info-form-container{
        flex-direction: row;
    }
    .language-and-save-btn{
        flex-direction: row;
    }
    .save-btn-container{
        width: 25%;
    }
    .change-app-language{
        justify-content: flex-start;
        gap:20px;
    }

}
@media screen and (min-width: 1000px){

    .-user--info-S1 .dashboard-user-personal-info-row{
        flex-direction: row-reverse;
    }
    .profil-completion-perc .overall-progress-stats-elem {
        padding:25px;
    }
    .profil-completion-txt span{
        font-size: 14px !important;
    }
    .language-select-container{
        width: 30%;
    }
}
@media screen and (min-width: 1100px){
    .user-academic-info-form-art{
        display: block !important;
    }
    .user-personal-info-form-content{
        width: 60%;
    }
    .user-academic-info{
        width: 450px;
    }
    .user-academic-info img{
        width: 100%;
        height:100%;
    } 
    .user-personal-info-form-container{
        justify-content: space-between;
    }
}







/*
.user-academic-info{
    width: 100%;
    height: 400px;
}
.user-academic-info img{
    width: 100%;
    height:100%;
    object-fit: contain;
}*/
/*
profil-completion-perc-content -gray-stats--bar = profil-completion-perc-content
profil-completion-perc-ring = profil-completion-perc-ring
profil-completion-perc-content = profil-completion-perc-ring-content
*/


/*Custom Checkbox*/
/* The container */
.user-personal-info-section .checkbox-container{
    display: flex;
    width: 100%;
    gap:10px;
}
.user-personal-info-section label{
    width: 20px !important;
    margin-top: 3px !important;
}
.user-personal-info-section .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .user-personal-info-section .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  
  /* When the checkbox is checked, add a blue background */
  .user-personal-info-section .container input:checked ~ .checkmark {
    background-color: black;
  }
  
  .user-personal-info-section .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px black solid;
    background: none;
    border-radius: 50% !important;
}


  /* Show the checkmark when checked */
  .user-personal-info-section .container input:checked ~ .checkmark:after {
    display: none !important;
  }


